import React, { useEffect, useState } from "react";
import FeaturedCard from "../../Reusables/Cards/FeaturedCard";
import AnimatedText1 from "../../Reusables/Texts/AnimatedText1";
import { useEvent } from "../../../api/events-hook";
import moment from 'moment';
import images from '../../../assets';
import { Link } from "react-router-dom";
import Loader from "../../../UI_Elements/loader/Loader";

const FeaturedEventSection = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabsData = [
    {
      label: "Events",
      content: "Not available",
    },
    {
      label: "Locations",
      content: "Not available",
    },
  ];

  // const [event, setEvent] = useState(null)
  const [eventList, setEventList] = useState(null)

  const {statusLoading, getEvent} = useEvent()

    useEffect(()=>{
        getEvent("LIST-UPCOMING-EVENT-PAGINATED", `?page_size=20&page_number=1`).then((res) => {
            
            if(res.events.length !== 0){
                setEventList([])
                for(let file of res.events){
                    setEventList((eventList)=>[...eventList, {
                        "id":file.id,
                        "title": file.name,
                        "time": moment(file.start_time).format('ddd, MMM DD · HH:mm:ss A'),
                        "location": `${file.address}, ${file.state}`,
                        "icon": images.eventImg.src,
                        "iconAlt": images.eventImg.alt,
                        "image":file?.image,
                        "event_type": file?.event_type
                    }])
                }
            }
            


        }).catch((error) => {
            console.log(error)
        })

    },[getEvent])

  return (
    <section className="py-20 px-14 overflow-hidden">
      <div className="flex flex-col items-center">
        <AnimatedText1
          text="Events For You"
          className="text-2xl sm:text-4xl text-center slg:text-5xl font-bold text-text-color-400"
        />

        {/* <ul className="flex space-x-6 mt-4 md:mt-16">
          {tabsData.map((tab, idx) => (
            <li key={idx}>
              <a href="/" rel="noreferrer"
                className={`cursor-pointer text-base sm:text-xl border-b border-transparent 
                ${activeTab === idx ? "text-text-color-400 font-bold" : "text-text-color-300"} 
                relative`}
                onClick={() => setActiveTab(idx)}
              >
                {tab.label}
                <span className={`h-[1px] inline-block bg-primary hover:bg-primary absolute left-[.3rem] -bottom-[.3rem] group-hover:w-full transition-[width] ease duration-300 ${activeTab === idx ? 'w-[80%]' : 'w-0'}`}>&nbsp;
                </span>
              </a>
            </li>
          ))}
        </ul> */}
        {statusLoading && <Loader/>}
        <div className={`py-4 transition ${activeTab === 0 ? "block" : "hidden"}`}>
          <div className="grid slg:grid-cols-2 gap-6 mt-12">
            { eventList && eventList.slice(0, 10).map((card, idx) => (
                <FeaturedCard key={idx} {...card} x={card.id % 2 === 0 ? 100 : -100} />
            ))}
            
            {eventList ===  null && <div className="flex justify-center w-full col-span-1 md:col-span-2">
              <p className="text-center">No Events Available</p>
              </div>}
          </div>

          <div className="flex justify-center w-full items-center mt-10">
            <Link to="/browse" className="!px-6 sm:!px-12 py-3 text-white !rounded-sm bg-primary-dark hover:scale-125 transition !text-xs sm:!text-base" textWhite shadow>See More</Link>
          </div>
        </div>

        <div className={`py-4 h-[10vh] ${activeTab === 1 ? "block" : "hidden"}`}>
          {tabsData[activeTab].content}
        </div>

      </div>
    </section>
  );
};

export default FeaturedEventSection; 