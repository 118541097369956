import React, { useMemo } from 'react';


const PaymentViewModal = ({ isOpen, onClose, purchaseDetails }) => {

    let url = useMemo(()=>{
        return purchaseDetails?.authorisation_url || ""

    },[purchaseDetails])
    
    
    if (!isOpen) {
        return null;
    }

    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="bg-gray-400 bg-opacity-75 absolute inset-0 -z-30" onClick={onClose} />
                
                <div classsName="bg-white w-full drop-shadow-lg">
                <button onClick={onClose} className="text-white w-full ml-auto ">Close Payment</button>
                <iframe
                    title="Paystack"
                    src={url}
                    className="h-[300px] md:w-[800px] md:h-[400px] overflow-auto "
                    allowFullScreen
                ></iframe>
                </div>
            </div>
            
        </>
    );
};

export default PaymentViewModal;
