import React, { useState } from 'react'
import NewPasswordComp from './components/NewPasswordComp'
import ResetPasswordConfirm from './components/ResetPasswordConfirm'
import HOC from '../HOC'


function NewPassword() {
    const [reset, setReset] = useState(true)
   
    const resetHandler = () => setReset(!reset)
    return (
        <HOC>
            {reset ? <NewPasswordComp resetHandler={resetHandler}/> : <ResetPasswordConfirm/>}
        </HOC>
    )
}

export default NewPassword