import { useHttpClient } from '../hooks/less-http-hook'
import { AuthContext } from '../context/auth-context'
import { useContext, useEffect, useState } from 'react'
import { useCallback, useMemo } from 'react';


export const useTicket = () =>{
    const {isLoading, error, sendRequest} = useHttpClient();

    const [statusLoading, setStatusLoading] = useState(isLoading)

    const eventEndpoints = useMemo(() => {
        return {
          'INITIATE-PURCHASE': '/ticket/purchase-initiate',
          'FINALISE-PURCHASE': '/ticket/purchase-finalise',
          'TERMINATE-PURCHASE': '/ticket/purchase-terminate',
          'VALIDATE-PURCHASE': '/ticket/validate',
          'INITIATE-CANCELLATION': '/ticket/cancel-initiate',
          'LIST-ALL-TICKETS': '/ticket/list-all',
          'LIST-PAST-TICKETS': '/ticket/list-past',
          'LIST-UPCOMING-TICKETS': '/ticket/list-upcoming',
          'TICKETS-DETAILS': '/ticket/details'
        };
      }, []);

    useEffect(() => {
        setStatusLoading(isLoading)
    },[isLoading])
    

    const auth = useContext(AuthContext)

    const postTicket = useCallback( async(id,data,params='') => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${eventEndpoints[id]}${params}`, "POST", data, {Authorization: 'Bearer '+auth.token});
            return responseData
        
        } catch (err) {
            console.log(error)
        }
    },[ auth.token, error, sendRequest, eventEndpoints])

    const updateTicket = useCallback( async(id,data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${eventEndpoints[id]}`, "PATCH", data, {Authorization: 'Bearer '+auth.token});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest, eventEndpoints])

    const getTicket = useCallback( async(id,params='') => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${eventEndpoints[id]}${params}`, "GET", null, {Authorization: 'Bearer '+auth.token});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[ auth.token, error, sendRequest, eventEndpoints])

    

    return {statusLoading, postTicket, updateTicket, getTicket}
}