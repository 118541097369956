import React from 'react';
import images from '../../../assets';
import { motion } from 'framer-motion';

const AnimatedAboutImgs = () => {
    return (
        <div className='hidden w-[70%] mx-auto relative xl:flex'>
            {/* Images */}
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 0.7 }, opacity: 1 }}
                src={images.aboutImg1.src}
                alt={images.aboutImg1.alt}
                className='absolute top-24 left-[3rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 0.2 }, opacity: 1 }}
                src={images.aboutImg2.src}
                alt={images.aboutImg2.alt}
                className='absolute top-8 left-[18rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 0.9 }, opacity: 1 }}
                src={images.aboutImg3.src}
                alt={images.aboutImg3.alt}
                className='absolute top-16 left-[44rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 0.7 }, opacity: 1 }}
                src={images.aboutImg4.src}
                alt={images.aboutImg4.alt}
                className='absolute top-[18rem] left-[42rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 0.5 }, opacity: 1 }}
                src={images.aboutImg5.src}
                alt={images.aboutImg5.alt}
                className='absolute top-[12rem] left-[28rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 0.5 }, opacity: 1 }}
                src={images.aboutImg6.src}
                alt={images.aboutImg6.alt}
                className='absolute top-[18rem] left-[14rem]'
            />

            {/* Circles */}
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 1.1 }, opacity: 1 }}
                src={images.aboutCircleImg1.src}
                alt={images.aboutCircleImg1.alt}
                className='absolute top-[15rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 1.2 }, opacity: 1 }}
                src={images.aboutCircleImg2.src}
                alt={images.aboutCircleImg2.alt}
                className='absolute top-[19rem] left-[7rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 1.3 }, opacity: 1 }}
                src={images.aboutCircleImg3.src}
                alt={images.aboutCircleImg3.alt}
                className='absolute top-[14rem] left-[19rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 1.4 }, opacity: 1 }}
                src={images.aboutCircleImg4.src}
                alt={images.aboutCircleImg4.alt}
                className='absolute top-[9rem] left-[25rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 1.5 }, opacity: 1 }}
                src={images.aboutCircleImg5.src}
                alt={images.aboutCircleImg5.alt}
                className='absolute top-[5rem] left-[31rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 1.6 }, opacity: 1 }}
                src={images.aboutCircleImg6.src}
                alt={images.aboutCircleImg6.alt}
                className='absolute top-[9rem] left-[38rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 1.7 }, opacity: 1 }}
                src={images.aboutCircleImg7.src}
                alt={images.aboutCircleImg7.alt}
                className='absolute top-[16rem] left-[42rem]'
            />
            <motion.img
                initial={{ opacity: 0 }}
                whileInView={{ transition: { duration: 1, ease: 'easeInOut', delay: 1.9 }, opacity: 1 }}
                src={images.aboutCircleImg8.src}
                alt={images.aboutCircleImg8.alt}
                className='absolute top-[15rem] left-[54rem]'
            />
        </div>
    );
};

export default AnimatedAboutImgs;
