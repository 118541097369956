import React from 'react';
import AnimatedLoader from '../Loader/AnimatedLoader';
// import { LoaderSvgIcon } from '../SvgIcons';

const LoaderModal = ({ isOpen, onClose }) => {
    if (!isOpen) {
        return null;
    }
    return (
        <div className="fixed inset-0 flex items-center justify-center z-30">
            <div className="bg-gray-400 bg-opacity-75 absolute inset-0" />
            <div className="bg-white rounded-lg py-5 drop-shadow-lg xl:w-[15%] mt-20 mx-4 xs:mx-8 md:mx-0">
                <div className="flex flex-col items-center gap-6">
                    {/* <LoaderSvgIcon /> */}
                    <AnimatedLoader />
                    <h2 className="text-lg font-semibold text-text-color-400">Loading...</h2>
                </div>
            </div>
        </div>
    );
};

export default LoaderModal;