import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import * as Icons from "react-icons/sl";

const ScrollToTopButton = () => {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    setScrolled(window.scrollY > 380);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <motion.button
      initial={{ y: 30, opacity: 0 }}
      whileInView={{ y: 0, transition: { duration: 0.4, ease: 'easeInOut', }, opacity: 1 }}
      type="button"
      className={`fixed bottom-10 right-8 z-50 ${scrolled ? "lg:block" : "hidden"} hidden bg-primary-dark rounded-full p-2`}
      onClick={scrollToTop}
    >
      <Icons.SlArrowUp className="text-white text-3xl" />
    </motion.button>
  );
};

export default ScrollToTopButton;