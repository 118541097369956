import React, { useEffect, useMemo, useState } from 'react'

import Board from "./Board"
import Tickets from './Tickets'
import { useTicket } from '../../../api/ticket-hook'
import Loader from '../../../UI_Elements/loader/Loader'

function Index() {
  const {statusLoading, getTicket} = useTicket()
  const [ticketAll, setTicketAll] = useState([])
  const [ticketPast, setTicketPast] = useState([])
  // const [ticketSaved, setTicketSaved] = useState([])
  const [ticketUpcoming, setTicketUpcoming] = useState([])
  const [ticketdataTable, setTicketdataTable] = useState("all")


  const data = useMemo(() => JSON.parse(localStorage.getItem('profileData')),[])

  useEffect(()=>{
    const fetchData = () => {
    
      getTicket("LIST-ALL-TICKETS", `?user_id=${data.user.id}`).then((res) => {
        
          setTicketAll(res.tickets || [])
    
        }).catch((error) => {
            console.log(error)
        })
    
        getTicket("LIST-PAST-TICKETS", `?user_id=${data.user.id}`).then((res) => {
            
            setTicketPast(res || [])
      
          }).catch((error) => {
            console.log(error)
        })

        getTicket("LIST-UPCOMING-TICKETS", `?user_id=${data.user.id}`).then((res) => {
          
            setTicketUpcoming(res || [])
      
          }).catch((error) => {
            console.log(error)
        })
    }

    fetchData()


    },[getTicket, data])


  const cardHandler = (card) => {
    setTicketdataTable(card)
  }

  
  return (
    <> 
        <Board ticketAll={ticketAll} ticketPast={ticketPast} ticketUpcoming={ticketUpcoming} cardHandler={cardHandler} data={data}/>
        {ticketdataTable === "all" && ticketAll.length === 0 && (
          <p className='my-12 text-center font-semibold '>You currently have no ticket</p>
        )}
        {ticketdataTable === "upcoming" && ticketUpcoming.length === 0 && (
          <p className='my-12 text-center font-semibold '>You currently have no upcoming ticket</p>
        )}
        {ticketdataTable === "past" && ticketPast.length === 0 && (
          <p className='my-12 text-center font-semibold '>You currently have no past ticket</p>
        )}
        {/* {ticketdataTable === "saved" && ticketSaved.length === 0 && (
          <p className='my-12 text-center font-semibold '>You currently have no saved ticket</p>
        )} */}

        {statusLoading && (
          <Loader/>
        )}
        <Tickets ticketAll={ticketAll} ticketPast={ticketPast} ticketUpcoming={ticketUpcoming} ticketdataTable={ticketdataTable}/>
    </>
  )
}

export default Index