import React from 'react'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'

// custom components
import Input from '../UI_Elements/Input/Input'

// custom util function
import {VALIDATOR_PASSWORD} from '../util/validators'

// custom hooks
import {useForm} from '../hooks/form-hook'
import { useHttpClient } from '../hooks/http-hook'

//css scripts
import 'react-phone-input-2/lib/style.css'
import { images } from '../assets'
import {MdOutlineArrowBack} from 'react-icons/md'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import HOC from './HOC'


function CreatePassword() {
    const [searchParams] = useSearchParams();

    const history = useNavigate()
    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        password: {
            value: '',
            isValid: false
        },
        passwordConfirm: {
            value: '',
            isValid: false
        }
    }, false);

    const authSubmitHandler = async(e) =>{
        e.preventDefault();

        

        try{
            const data = new FormData()

            data.append("first_name", searchParams.get("firstname"))
            data.append("last_name", searchParams.get("lastname"))
            data.append("phone_number", searchParams.get("phonenumber"))
            data.append("email_address", searchParams.get("email"))
            data.append("password", formState.inputs.password.value)
            data.append("password_confirmation", formState.inputs.passwordConfirm.value)



            const responseData = await
                sendRequest(`${process.env.REACT_APP_API_CALL}/user/signup`, 'POST', data, {});
                
            if(responseData){
                
                localStorage.setItem('profileData', JSON.stringify(responseData.data))
                history(`/verification?token=${responseData.data.token}&userId=${responseData.data.user.id}&email=${responseData.data.user.email_address}`)
            }
        }
        catch(err){
            console.log(error)
        }

        
        
    }

    return (
        <HOC>
            <div className='mx-auto' data-aos="fade-left">
                <div className='text-center'>
                    <img src={images.passwordIcon["src"]} alt={images.passwordIcon["alt"]} className="mx-auto mb-4"/>
                    <h3 className='font-semibold text-xl md:text-2xl text-primary-black mb-2'>Create Password</h3>
                    <p className='text-primary-black-70 mx-auto text-center text-sm md:text-base'>Your password must be at least 8 characters with one or more <br/>special character</p>
                </div>

                <form className='my-4 px-4 md:px-0 md:w-96 ' onSubmit={authSubmitHandler}>
                    <Input
                        type="password"
                        placeholder='Enter password'
                        label="Choose Password"
                        labelClassName={`text-xs mb-2 font-semibold form_login_label`}
                        id="password"
                        validators={[VALIDATOR_PASSWORD(8)]}
                        errorText="Please enter a valid password"
                        onInput={inputHandler}
                        divClassName={`mb-3 `}
                        containerClassName={`relative  `}
                        className={`appearance-none ${formState.inputs.password.value && "bg-light-purple"}  
                        input-form p-3 w-full text-primary-black focus:outline-none 
                        focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>
                    
                    <Input
                        type="password"
                        placeholder='Confirm password'
                        label="Confirm password"
                        labelClassName={`text-xs mb-2 font-semibold form_login_label`}
                        id="passwordConfirm"
                        validators={[VALIDATOR_PASSWORD(8)]}
                        errorText="Please enter a valid password"
                        onInput={inputHandler}
                        divClassName={`mb-3 `}
                        containerClassName={`relative  `}
                        className={`appearance-none 
                        input-form p-3 w-full text-primary-black focus:outline-none 
                        focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>


                    {/* <input
                        type="submit"
                        disabled={formState.isValid ? false:true}
                        className={`w-full ${formState.isValid ? "bg-primary cursor-pointer":"bg-primary-gray cursor-not-allowed" } text-white py-3 rounded-xl mt-6`}
                        value="Create Account"/> */}
                    <button 
                    type="submit" 
                    disabled={formState.isValid ? false:true} 
                    className={`flex items-center justify-center w-full ${formState.isValid ? "bg-primary cursor-pointer":"bg-primary-gray cursor-not-allowed" }
                    cursor-pointer bg-primary text-white py-3 rounded-xl mt-4`}>
                        Create Account {isLoading && <span className='text-white animate-spin ml-2'><AiOutlineLoading3Quarters/></span>}
                    </button>

                    <Link to={`/signup`} className='flex__center mt-4 text-primary-black-70'>
                        <MdOutlineArrowBack />
                        <p >Back</p>
                    </Link>
                </form>

                
            </div>
        </HOC>
    )
}

export default CreatePassword