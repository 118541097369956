import React from 'react'

// custom components
import Input from '../../../UI_Elements/Input/Input'

// custom util function
import {VALIDATOR_EMAIL} from '../../../util/validators'
import {MdOutlineArrowBack} from 'react-icons/md'

// custom hooks
import {useForm} from '../../../hooks/form-hook'

import { Link } from 'react-router-dom'
import { images } from '../../../assets'
import { useHttpClient } from '../../../hooks/http-hook'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'


function ForgotComp(props) {

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({

        email: {
            value: '',
            isValid: false
        }
    }, false);

    const forgotSubmitHandler = async(e) =>{
        e.preventDefault()
        
        try {

            const formData = new FormData()

            formData.append("email_address",formState.inputs.email.value )
            props.emailHandler(formState.inputs.email.value )

            const response = await sendRequest(`${process.env.REACT_APP_API_CALL}/user/password-reset-initiate`, 'POST', formData, {});

            if(response){
                props.checkHandler()
            }   
            

        } catch (err) {
            
            console.log(error)
        }
        
    }
    
  return (
    <div className='mx-auto' data-aos="fade-left">
            <div className='text-center'>
                <img src={images.passwordIcon[0]} alt={images.passwordIcon[1]} className="mx-auto mb-6"/>
                <h3 className='font-bold text-xl md:text-2xl text-black-text mb-2'>Forgot password?</h3>
                <p className='text-black-gray text-sm md:text-base'>No worries, we’ll send you reset instructions.</p>
            </div>

            <form className='my-4 mt-8 px-4 md:px-0 md:w-96' onSubmit={forgotSubmitHandler}>
                <Input
                    type="email"
                    placeholder='Enter your email'
                    label="Email*"
                    labelClassName={`text-xs mb-2 font-semibold form_login_label`}
                    id="email"
                    validators={[VALIDATOR_EMAIL()]}
                    errorText="Please enter a valid email"
                    onInput={inputHandler}
                    divClassName={`mb-3 `}
                    containerClassName={`relative  `}
                    className={`appearance-none 
                    input-form p-3 text-vlack-text focus:outline-none 
                    focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>


                
                <button type="submit" className={`flex items-center justify-center w-full 
                cursor-pointer bg-primary text-white py-3 rounded-xl mt-4`}>
                    Reset password {isLoading && <span className='text-white animate-spin ml-2'><AiOutlineLoading3Quarters/></span>}
                </button>
            </form>

           

            <Link to='/login' className='flex__center mt-4 font-semibold text-primary-black-70'>
                <MdOutlineArrowBack/>
                <p className='text-sm'>Back to log in</p>
            </Link>


            
        </div>
  )
}

export default ForgotComp