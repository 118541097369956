import { useHttpClient } from '../hooks/less-http-hook'
import { AuthContext } from '../context/auth-context';
import { useContext, useEffect, useState } from 'react'
import { useCallback } from 'react';


export const useProfile = () =>{
    const {isLoading, error, sendRequest} = useHttpClient();

    const [userProfile, setUserData] = useState()
    const [profileLoading, setProfileLoading] = useState(isLoading)

    useEffect(() => {
        setProfileLoading(isLoading)
    },[isLoading])
    

    const auth = useContext(AuthContext)

    const profileData = useCallback( async(email_address) => {
        try {
            // console.log(email_address)
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/user/details?email_address=${email_address}`, "GET", null, {Authorization: 'Bearer '+auth.token});
            // console.log(responseData)
            if(responseData){
                setUserData(responseData.data.user)
            }
            
            // console.log(responseData)
            return responseData.data.user
        } catch (err) {
            console.log(error)
        }
    },[ error, sendRequest, auth.token])

    const updateProfileData = useCallback( async(data, email_address) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}/user/update?email_address=${email_address}`, "POST", data, {Authorization: 'Bearer '+auth.token});
            
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    const updateImageData = useCallback( async(data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}admin/change-image`, "POST", data, {Authorization: 'Bearer '+auth.token});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest])

    

    return {userProfile, profileLoading, profileData, updateProfileData, updateImageData}
}