
import LandingPageMain from "../../components/PageFragments/LandingPageHome/Index";

const Index = () => {

  return (
    <LandingPageMain />
  );
};

export default Index;