import Home from './Home/Index';
import About from './About/Index';
import Browse from './Browse/Index';
import Events from './Events/Index';
import ticketValidation from './ticketValidation/Index'

const pages = {
    Home,
    About,
    Browse,
    Events,
    ticketValidation
}

export default pages