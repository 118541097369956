import DashboardOverview from './DashboardOverview/Index';
import Settings from './Settings/Index'
import Ticket from './Ticket/Index'


const dashboard = {
    DashboardOverview,
    Settings,
    Ticket
}

export default dashboard