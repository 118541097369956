import './auth.css'


import Login from "./Login";
import Signup from "./Signup";
import CreatePassword from './CreatePassword';
import Verification from './Verification';
import index from "./forgotpassword/index"


const exportedObject = {
    Login,
    Signup,
    CreatePassword,
    Verification,
    Forgotpassword: index.Forgotpassword,
    NewPassword: index.NewPassword
}

export default exportedObject