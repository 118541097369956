import React, { useContext, useState } from 'react';
import { FiShoppingBag } from 'react-icons/fi';
import * as Icontb from "react-icons/lia";
import LoaderModal from './LoaderModal';
import BookingSuccessModal from './BookingSuccessModal';
import { number_format, createNumberList } from '../../../util/functions';
import { useNavigate, useParams } from 'react-router-dom';
import { useTicket } from '../../../api/ticket-hook';
import PaymentViewModal from './PaymentViewModal';
import {DataContext} from '../../../context/DataContext'
import _ from 'lodash';


const TicketOption = ({ option, selectedOption, handleSelectChange, options, index }) => (
    <div className="flex justify-between py-2 px-2 rounded-md items-center border border-text-color-100">
        <div className="flex flex-col gap-2">
            <span className='text-sm sm:text-base text-text-color-300'>{option.label}</span>
            <h3 className='text-lg sm:text-xl font-bold'>{`₦${number_format(option.price||0) || 0}`}</h3>
        </div>
        <select
            onChange={(e) => {
                handleSelectChange(option.label, option.price, e.target.value, index)
            }}
            className='text-sm sm:text-base rounded-sm border w-12 border-text-color-100 p-1 focus:outline-none cursor-pointer transition'
        >
            {options.map((opt) => (
                <option key={opt} value={opt}>
                    {opt}
                </option>
            ))}
        </select>
    </div>
);

const TicketCheckoutModal = ({ isOpen, onClose, address, state, name, time, availability, event_type, date }) => {

    const data = useContext(DataContext)

    const navigate = useNavigate()
    
    const [selectedAvailability, setSelectedOption] = useState((prevData) => {
        let copyData = _.cloneDeep(availability)
        
        for (const key in copyData) {
            if (copyData.hasOwnProperty(key)) {
                copyData[key].quantity = 0;
            }
        }

        return copyData
    });

    const [purchaseDetails, setPurchaseDetails] = useState(null);
    const [totalCost, setTotalCost] = useState(0);
    // const [isValidTickets, setIsValidTickets] = useState(false)
    const [isLoaderModalOpen, setIsLoaderModalOpen] = useState(false);
    const [isBookingSuccessModalOpen, setIsBookingSuccessModalOpen] = useState(false);
    const [paymentViewModalOpen, setPaymentViewModalOpen] = useState(false);

    const {eventId} = useParams()

    const profileData = JSON.parse(localStorage.getItem('profileData'));

    // const paymentReference = useMemo(()=>{
    //     if(purchaseDetails !== null){
    //         return purchaseDetails
    //     }else{
    //         return null
    //     }
    // },[purchaseDetails])

    let paymentReference = null

    const {postTicket} = useTicket()


    const closeLoaderModal = () => {
        setIsLoaderModalOpen(false);
    };

    const storeEventUrlInLocalStorage = () => {
        // Get the pathname from window.location
        const pathname = window.location.pathname;

        // Get the search query string from window.location
        const searchParams = new URLSearchParams(window.location.search);

        // Combine the pathname and query parameter into a single string
        const eventUrl = `${pathname}?${searchParams.toString()}`;
        // console.log(eventUrl)
        localStorage.setItem('eventUrl', eventUrl);
      };

    const openBookingSuccessModal = () => {
        setIsLoaderModalOpen(true); // Trigger the loader state

        if(data.userData && Object.keys(data?.userData)?.length === 0 ){
            storeEventUrlInLocalStorage()
            navigate("/login")
            
        }

        setIsLoaderModalOpen(false);
        
        const outputAvailability = {};
        let quantity = 0

        for (const key in selectedAvailability) {
            if (selectedAvailability.hasOwnProperty(key)) {
                const item = selectedAvailability[key];
                quantity += item.quantity
                outputAvailability[key] = item.quantity;
            }
        }

        if(quantity === 0){
            alert("Please select at least one ticket");
            return
        }

        const formData = new FormData()

        formData.append("user_id", profileData?.user?.id)
        formData.append("event_id", eventId)
        formData.append("ticket_info", JSON.stringify(outputAvailability))
        formData.append("ticket_date", new Date(date).getTime())
        formData.append("event_type", event_type)

        postTicket("INITIATE-PURCHASE",formData,`?event_type=${event_type}`).then((res) => {
            setPurchaseDetails(res.data)
            paymentReference = res.data
            paymentViewHandler()
        }).catch((error) => {
            console.log(error)
        })
        
    };

    const paymentViewHandler = () =>{
       
        if(paymentReference !== null){
            setPaymentViewModalOpen(true)
        }
    }

    const closeBookingSuccessModal = () => {
        setIsBookingSuccessModalOpen(false);
    };
    const closePaymentViewModal = () => {
        const outputAvailability = {};

        for (const key in selectedAvailability) {
            if (selectedAvailability.hasOwnProperty(key)) {
                const item = selectedAvailability[key];
                outputAvailability[key] = item.quantity;
            }
        }

        // const validateParams = `?user_id=${profileData?.user?.id}&code=${purchaseDetails?.reference}`
        // postTicket("VALIDATE-PURCHASE", {}, validateParams).then((res) => {
        //     alert("Couldn't finish payment")
        //     onClose()
        //     return
        // }).catch((error) => {
        //     console.log(error)
        // })

        const formData = new FormData()

        formData.append("user_id", profileData?.user?.id)
        formData.append("event_id", eventId)
        formData.append("transaction_id", purchaseDetails?.reference || "")
        formData.append("ticket_info", JSON.stringify(outputAvailability))
        formData.append("ticket_date", new Date(date).getTime())
        formData.append("event_type", event_type)

        postTicket("FINALISE-PURCHASE",formData,`?event_type=${event_type}`).then((res) => {
            setPurchaseDetails(res.data)
            setPaymentViewModalOpen(false);
            setIsBookingSuccessModalOpen(true);
            navigate("/dashboard")
        }).catch((error) => {
            console.log(error)
            alert("Couldn't finish payment")
            onClose()
        })

        
    };

    const handleSelectChange = (name, price, value,index) => {
        setSelectedOption(prevState => {
            let prevData  = {...prevState}

            prevData[name] = {
                price: price,
                quantity: parseInt(value, 10)
            }

            let totalPrice = 0
            for (const key in prevData) {
                if (prevData.hasOwnProperty(key)) {
                  const item = prevData[key];
                  totalPrice += item.price * item.quantity;
                }
            }

            setTotalCost(totalPrice)
            return prevData
        })

        
    };


    if (!isOpen) {
        return null;
    }

    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center z-30">
                <div className="bg-gray-400 bg-opacity-75 absolute inset-0" onClick={onClose} />
                <div className="bg-white rounded-lg pt-2 pb-10 drop-shadow-lg xl:w-[35%] mt-20 mx-4 xs:mx-8 md:mx-0 overflow-y-auto max-h-[calc(100vh-8rem)]">
                    <div className="flex justify-end px-2 sm:px-8 py-4">
                        <Icontb.LiaTimesSolid className='text-3xl cursor-pointer hover:scale-125 transition-[.5]' onClick={onClose} />
                    </div>

                    <div className="flex flex-col items-center gap-2 pb-3 px-2 sm:px-20">
                        <h2 className="text-xl mb-2 text-text-color-400 font-bold">Checkout</h2>
                        <p className='text-sm sm:text-base text-center text-text-color-400 leading-[1.8]'>{name || ""}</p>
                        <div className="flex flex-col items-center text-text-color-200 text-base gap-2">
                            <span className='text-sm sm:text-base text-center'>{`${address || ""}, ${state || ""}`}</span>
                            {!event_type ? <span className='text-sm sm:text-base text-center'>{time || "not set"}</span> : <span className='text-sm sm:text-base text-center'>Ticket Date: {date || "not set"}</span>}
                        </div>
                    </div>

                    <div className="px-4 sm:px-10 text-text-color-400">
                        <h3 className='text-lg sm:text-xl'>Tickets</h3>
                        <div className="flex flex-col gap-6 mt-2">

                            {availability && Object.keys(availability).map((item, index) =>{
                                
                                const itemKey = availability[`${item}`]

                                if(itemKey.quantity <= 0){
                                    return(
                                    <div key={index} className="flex justify-between py-2 px-2 rounded-md items-center border border-text-color-100">
                                        <div className="flex flex-col gap-2">
                                            <span className='text-sm sm:text-base text-text-color-300'>{item || ""}</span>
                                            <h3 className='text-lg sm:text-xl font-bold'>₦{number_format(itemKey.price||0)||0}</h3>
                                        </div>
                                        <span className='text-primary text-sm sm:text-base'>Sold Out!</span>
                                    </div>)
                                }

                                return(
                                    <div key={index}>
                                        <TicketOption
                                            option={{ label:`${item}`, price: itemKey.price }}
                                            selectedOption={0}
                                            index={index}
                                            handleSelectChange={handleSelectChange}
                                            options={createNumberList(itemKey.quantity || 0)}
                                        />
                                    </div>
                                )
                            })
                            }
                            
                            

                           
                        </div>
                    </div>

                    <div className="flex flex-wrap gap-4 lg:gap-0 justify-between mt-12 px-4 sm:px-10">
                        <div className="flex gap-3 sm:gap-6 items-center">
                            <FiShoppingBag className='text-xl sm:text-3xl text-primary' />
                            <h3 className='font-bold text-base sm:text-2xl'>₦{number_format(totalCost)}</h3>
                        </div>
                        <button
                            className="py-2 md:py-2 px-16 bg-primary-dark text-white text-xs md:text-base font-bold rounded transition-[.3] hover:scale-105"
                            onClick={openBookingSuccessModal}
                        >
                            Pay Now
                        </button>
                    </div>

                    <p></p>
                </div>
            </div>
            <LoaderModal
                isOpen={isLoaderModalOpen}
                onClose={closeLoaderModal}
            />
            <BookingSuccessModal
                isOpen={isBookingSuccessModalOpen}
                onClose={closeBookingSuccessModal}
            />

            <PaymentViewModal
            isOpen={paymentViewModalOpen}
            onClose={closePaymentViewModal}
            purchaseDetails={purchaseDetails}
            />
        </>
    );
};

export default TicketCheckoutModal;
