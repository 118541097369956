import React from 'react';
import HeroSection from './HeroSection';
import AboutUsSection from './AboutUsSection';
import LeadershipTeamSection from './LeadershipTeamSection';

const Index = () => {
    return (
        <>
            <HeroSection />
            <AboutUsSection />
            <LeadershipTeamSection />
        </>
    );
};

export default Index;