import React, { useEffect, useState } from 'react';
import * as Iconb from "react-icons/bi";
// import { featuredCardsItems } from '../../../constants';
import FeaturedCard from '../../Reusables/Cards/FeaturedCard';
import MultipleCardSection from './MultipleCardSection';
import AnimatedText3 from '../../Reusables/Texts/AnimatedText3';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEvent } from '../../../api/events-hook';
import moment from 'moment';
import images from '../../../assets';

import Loader from '../../../UI_Elements/loader/Loader';
import LocationMultipleCardSection from './LocationMultipleCardSection';

const Index = () => {
   const navigate = useNavigate();
   const {eventId} = useParams()
   const [searchParams] = useSearchParams();

   const [eventDetails, setEventDetails] = useState()
   
    // const [event, setEvent] = useState(null)
    const [eventList, setEventList] = useState(null)

   const {statusLoading, getEvent} = useEvent()

   useEffect(()=>{
    if(localStorage.getItem('eventUrl')){
        localStorage.removeItem('eventUrl');
    }
    getEvent("EVENT-DETAILS", `?event_id=${eventId}&event_type=${searchParams.get('event_type')}`).then((res) => {
        
        setEventDetails(res.event)

    }).catch((error) => {
        console.log(error)
    })

    getEvent("LIST-UPCOMING-EVENT-PAGINATED", `?page_size=10&page_number=1&event_type=${searchParams.get('event_type')}`).then((res) => {

        if(res.events.length !== 0){
            setEventList([])
            for(let file of res.events){
                setEventList((eventList)=>[...eventList, {
                    "id":file.id,
                    "title": file.name,
                    "time": moment(file.start_time).format('ddd, MMM DD · HH:mm:ss A'),
                    "location": `${file.address}, ${file.state}`,
                    "icon": images.eventImg.src,
                    "iconAlt": images.eventImg.alt,
                    "image":file?.image,
                    "event_type":file?.event_type
                }])
            }
        }
        
    }).catch((error) => {
        console.log(error)
    })

    },[getEvent, eventId, searchParams])


    return (
        <section className='py-10 pt-28 px-4 sm:px-14 pb-32 sm:pb-44'>
            <div className="flex w-fit mx-auto py-2 px-6 gap-3 items-center border rounded-md">
                <span
                    className='text-xs xs:text-sm md:text-base text-primary cursor-pointer hover:text-primary-dark'
                    onClick={() => navigate('/browse')}
                >Browse</span>
                <Iconb.BiCaretRight className="text-base transition text-white bg-primary rounded-full" />
                <span className='text-xs xs:text-sm md:text-base'>Event-details</span>
            </div>

            {
                searchParams.get('event_type') !== 'location' ? 
                (<>
                    {statusLoading ? <Loader/> :<MultipleCardSection {...eventDetails}/>}
                </>) : 
                (<>
                    {statusLoading ? <Loader/> :<LocationMultipleCardSection {...eventDetails}/>}
                </>)
            }
            <AnimatedText3
                text="More event from organizer"
                className="text-xl lg:text-2xl font-bold text-text-color-400 text-center my-12"
            />
            <div className="grid slg:grid-cols-2 gap-6 mt-12 px-8 xs:px-16 sm:px-8 lg:px-0 xl:px-32">
                {eventList && eventList.slice(0, 4).map((card, idx) => (
                    <FeaturedCard key={idx} {...card} />
                ))}
                {eventList && eventList.length === 0 && (
                    <>
                        {searchParams.get('event_type') !== 'location' ? <p className="text-center">No Events Available</p> : <p className="text-center">No Locations Available</p>}
                    </>
                )}
            </div>
        </section>
    );
};

export default Index;