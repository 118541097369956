import React from 'react';
import { motion } from 'framer-motion';

const AboutImgCard = ({ id, title, description, img, imgAlt, delay }) => (
    <div
        key={id}
        className="flex flex-col w-fit md:w-[18%] gap-1 lg:gap-2 transition cursor-pointer">
        <motion.img
            whileHover={{ scale: 1.07 }}
            initial={{opacity: 0}}
            whileInView={{ y: 0, transition: { duration: .6, ease: 'easeIn', delay: delay}, opacity: 1 }}
            src={img}
            alt={imgAlt}
            className='w-fit md:w-full rounded-2xl'
        />
        <h3 className='text-xs xs:text-base md:text-base lg:text-lg text-text-color-400 hover:text-text-color-300 transition font-bold text-center md:text-start'>{title}</h3>
        <span className='text-text-color-300 text-xxs md:text-xs lg:text-sm text-center md:text-start hover:scale-105 transition'>{description}</span>
    </div>
);

export default AboutImgCard;