import React from 'react';
import { CopyLinkSocialIcon, InstagramSocialIcon, MessagesSocialIcon, MessengerSocialIcon, TelegramSocialIcon, TwitterSocialIcon, WhatsAppSocialIcon } from '../SocialIcons';

const SocialShareModal = ({ isOpen, onClose }) => {
    if (!isOpen) {
        return null;
    }

    const socialIcons = [
        { Icon: CopyLinkSocialIcon, label: 'Copy url' },
        { Icon: WhatsAppSocialIcon, label: 'WhatsApp' },
        { Icon: InstagramSocialIcon, label: 'Direct' },
        { Icon: TelegramSocialIcon, label: 'Telegram' },
        { Icon: MessengerSocialIcon, label: 'Messenger' },
        { Icon: TwitterSocialIcon, label: 'Twitter' },
        { Icon: MessagesSocialIcon, label: 'Messages' },
    ];

    return (
        <div className="fixed inset-0 flex items-center justify-center z-30">
            <div className="bg-gray-400 bg-opacity-75 absolute inset-0" onClick={onClose} />
            <div className="bg-white rounded-lg pt-2 pb-10 drop-shadow-lg xl:w-[35%] mt-20 mx-4 xs:mx-8 md:mx-0">
                <div className="flex flex-col items-center gap-6 pb-3">
                    <hr className='w-10 h-[3px] bg-[#C4C4C4]' />
                    <h2 className="text-lg font-semibold mb-2 text-text-color-400">Social Share</h2>
                </div>

                <hr />
                <div className="grid grid-cols-4 px-4 md:px-8 mx-auto mt-6 gap-2 md:gap-4">
                    {socialIcons.map(({ Icon, label }, index) => (
                        <div key={index} className="flex flex-col justify-center items-center gap-2 cursor-pointer">
                            <Icon />
                            <span className="text-text-color-400 text-xs md:text-sm">{label}</span>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center mt-12 px-10">
                    <button
                        className="w-full py-2 md:py-4 border-gray-600 border-[1px] text-text-color-400 text-xs md:text-base font-bold rounded hover:bg-primary hover:text-white hover:border-primary transition"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SocialShareModal;
