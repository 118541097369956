import React, { useCallback, useContext } from 'react'
import Modal from '../../Reusables/Modal/Modal'

import { images } from '../../../assets'
import PhotoCard from './components/PhotoCard'
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHttpClient } from '../../../hooks/http-hook';
import { AuthContext } from '../../../context/auth-context';
import Loader from '../../../UI_Elements/loader/Loader';
import { DataContext } from '../../../context/DataContext';

function PhotoUpload({ onUpload, show, onCancel }) {
  const [imagesLoaded, setImagesLoaded] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const {isLoading, sendRequest} = useHttpClient();

    
  const auth = useContext(AuthContext)
  const dataContext = useContext(DataContext)

  // Handler for when files are dropped or selected
  const onDrop = useCallback((acceptedFiles) => {
    const imagesDrop = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file), // Create a preview URL for displaying the image
      })
    );

  setImagesLoaded((prevImages) => {
      let totalImages = [...prevImages, ...imagesDrop]

      onUpload(totalImages);
      return totalImages
  })
  
  }, [onUpload]);

  // Accept only image files
  const acceptFileTypes = ['.png', '.jpg']; 

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptFileTypes.map(type => type === '.png' ? 'image/png' : 'image/jpeg'),
    multiple: false,
    onDragEnter: () => setIsHovered(true),
    onDragLeave: () => setIsHovered(false),
  });

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const deleteImageHandler = (index) => {
    setImagesLoaded((prevImages) => {
        let prevArray = [...prevImages]
        prevArray.splice(index, 1);

        onUpload(prevArray);
        return prevArray
    });
  }


  const submitHandler= async() => {
    try {
      if(imagesLoaded[0].length === 0){
        return
      }
      const data = JSON.parse(localStorage.getItem('profileData'));
      // console.log(data["user"])
      const formData = new FormData()

      formData.append("image", imagesLoaded[0])
      formData.append("user_id", data?.user?.id)
      formData.append("image_type", "jpeg")
      formData.append("image_size", imagesLoaded[0].size)
      
      
      const response = await sendRequest(`${process.env.REACT_APP_API_CALL}/user/upload-image`, 'POST', formData, {Authorization: 'Bearer '+auth.token});

      // console.log(response)
      if(response){
        let resData = {
          ...data,
          user:{
            ...data.user, 
            profile_picture: response?.data?.profile_picture || ""}
        }
        let resContextData = {
            ...data.user, 
            profile_picture: response?.data?.profile_picture || ""
        }
        localStorage.setItem('profileData', JSON.stringify(resData))
        console.log(dataContext)
        
        dataContext.updateData(resContextData)
        onCancel()
        
      }
      
      
    } 
    catch (err) {
        console.log(err)
    }
  }


  return (
    <Modal show={show} onCancel={() => {
      setImagesLoaded([])
      onCancel()
    }} SubmitText="Save" CancelText="Cancel" 
    onSubmitButton = {submitHandler}>
        <div className='mb-6 mt-4'>
            <h3 className='font-semibold'>Upload and attach files</h3>
            <p className='text-sm'>Upload your profile picture.</p>
        </div>

        {/* Images drop zone section */}
        {imagesLoaded.length === 0 &&
          <div className='mb-6 web-border-8 border border-border-color border-dashed p-2' {...getRootProps()} 
          onMouseLeave={handleMouseLeave}>
            
            <input {...getInputProps()} />
            {isHovered && <p className='text-sm py-8 text-primary-black text-center font-medium'>Drop your image</p>}
            
            {!isHovered && (
                  <>
                      <img src={images.dropImageIcon["src"]} alt={images.dropImageIcon["alt"]} className="object-contain mx-auto mb-2"/>
                  </>
              )}
            <div className='flex justify-center items-center'>
              <input type="file" className='h-0 w-0' id='upload-photo'/>
              <label htmlFor="upload-photo" className='text-primary-green-dark hover:underline mr-1 font-medium'>Click to upload</label>
              <p className='text-primary-black-70 font-light'>or drag and drop</p>
            </div>
            <p className='text-primary-black-70 text-sm text-center font-light'>JPEG (max. 800x400px)</p>
          </div>
        }
        

        {/* Progress bar section */}
        {imagesLoaded.length !==0 &&
        <div className='web-border-8 border border-border-color border-dashed p-2 lg:p-3'>
           <PhotoCard file={imagesLoaded} deleteImageHandler={deleteImageHandler}/>
           {isLoading && <Loader/>}
        </div>
        }
    </Modal>
  )
}

export default PhotoUpload