import React from 'react';
import LandingPageEvent from '../../components/PageFragments/LandingPageEvent/Index';

const Index = () => {

    return (
        <LandingPageEvent />
    );
};

export default Index;