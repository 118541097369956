import React, { useContext } from 'react'
import {Link, useParams} from 'react-router-dom'

// custom components
import Input from '../../../UI_Elements/Input/Input'

// custom util function
import {VALIDATOR_REQUIRE} from '../../../util/validators'

// custom hooks
import {useForm} from '../../../hooks/form-hook'
import { useHttpClient } from '../../../hooks/http-hook'

//css scripts
import 'react-phone-input-2/lib/style.css'
import { images } from '../../../assets'
import {MdOutlineArrowBack} from 'react-icons/md'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { AuthContext } from '../../../context/auth-context'


function NewPasswordComp(props) {
    // const history = useNavigate()
    const {isLoading, error, sendRequest} = useHttpClient();
    const auth = useContext(AuthContext)

    const {email_address, token} = useParams()
    const [formState,
        inputHandler] = useForm({

        password: {
            value: '',
            isValid: false
        },
        passwordConfirm: {
            value: '',
            isValid: false
        }
    }, false);

    const authSubmitHandler = async(e) =>{
        e.preventDefault();

        try {

            const formData = new FormData()

            formData.append("email_address",email_address )
            formData.append("token",token )
            formData.append("password",formState.inputs.password.value )
            formData.append("password_confirmation",formState.inputs.passwordConfirm.value )

            const response = await sendRequest(`${process.env.REACT_APP_API_CALL}/user/password-reset-complete`, 'POST', formData, {});

            if(response){
                auth.login(token)
                props.resetHandler()
            }


        } catch (err) {
            
            console.log(error)
        }
        
    }

  return (
    <div className='mx-auto' data-aos="fade-left">
                <div className='text-center'>
                    <img src={images.passwordIcon[0]} alt={images.passwordIcon[1]} className="mx-auto mb-4"/>
                    <h3 className='font-bold text-xl md:text-2xl text-black-text mb-2'>Set new password</h3>
                    <p className='text-black-gray mx-auto text-center text-sm md:text-base'>
                        Your new password must be different to <br/> previously used passwords.
                    </p>
                </div>

                <form className='my-4 px-4 md:px-0 md:w-96 ' onSubmit={authSubmitHandler}>
                    <Input
                        type="password"
                        placeholder='Enter password'
                        label="Choose Password"
                        labelClassName={`text-sm mb-2 font-semibold form_login_label`}
                        id="password"
                        validators={[VALIDATOR_REQUIRE()]}
                        errorText="Please enter a valid password"
                        onInput={inputHandler}
                        divClassName={`mb-3 `}
                        containerClassName={`relative  `}
                        className={`appearance-none  
                        input-form p-3 w-full text-black-text focus:outline-none 
                        focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>
                    
                    <Input
                        type="password"
                        placeholder='Confirm password'
                        label="Confirm password"
                        labelClassName={`text-sm mb-2 font-semibold form_login_label`}
                        id="passwordConfirm"
                        validators={[VALIDATOR_REQUIRE()]}
                        errorText="Please enter a valid password"
                        onInput={inputHandler}
                        divClassName={`mb-3 `}
                        containerClassName={`relative  `}
                        className={`appearance-none 
                        input-form p-3 w-full text-black-text focus:outline-none 
                        focus:ring-none focus:border-primary focus:z-10 sm:text-sm`}/>


                    <button type="submit" className={`flex items-center justify-center w-full 
                    cursor-pointer bg-primary text-white py-3 rounded-xl mt-4`}>
                        Reset password {isLoading && <span className='text-white animate-spin ml-2'><AiOutlineLoading3Quarters/></span>}
                    </button>

                    <Link to={`/login`} className='flex__center mt-4 text-black-gray'>
                        <MdOutlineArrowBack />
                        <p >Back to login</p>
                    </Link>
                </form>

                
            </div>
  )
}

export default NewPasswordComp