import React from 'react'
import { IoIosArrowDroprightCircle } from 'react-icons/io'
import { Link } from 'react-router-dom'

function BreadCrumbNavigation({path, nav}) {
  return (
    <div className='w-fit mx-auto rounded flex justify-center items-center p-2 border border-text-color-100 mb-8'>
        <Link to="/dashboard" className='text-primary '>Dashboard</Link>
        <IoIosArrowDroprightCircle className='text-primary mx-4 text-lg'/>
        <Link to={path} className='text-black-text '>{nav}</Link>
    </div>
  )
}

export default BreadCrumbNavigation