import ChooseUs from './ChooseUs';
import FeaturedEventSection from './FeaturedEventSection';
// import GetMoreSection from './GetMoreSection';
import GetStartedSection from './GetStartedSection';
import HeroSection from './HeroSection';
// import ManageEventSection from './ManageEventSection';
// import ProductSection from './ProductSection';

const Index = () => {
    return (
        <>  
            
            <HeroSection />
            {/* <ProductSection /> */}
            <FeaturedEventSection />
            {/* <ManageEventSection /> */}
            <ChooseUs />
            {/* <GetMoreSection /> */}
            <GetStartedSection />
            
        </>
    );
};

export default Index;