import React, { useMemo } from 'react'
import TicketCard from './TicketCard'
import { useNavigate } from 'react-router-dom'

function Tickets({ticketAll, ticketPast, ticketUpcoming, ticketdataTable}) {
    const history = useNavigate()
    const ticketHandler = (number) =>{
        history(`/ticket/${number}`)
    }

    const data = useMemo(() => {
      switch (ticketdataTable) {
        case "saved":
          // console.log(ticketdataTable)
          return []
          
        case "upcoming":
          // console.log(ticketdataTable)
          return ticketUpcoming
          
        case "past":
          // console.log(ticketdataTable)
          return ticketPast

        default:
          // console.log(ticketdataTable)
          return ticketAll
      }
    }, [ticketAll, ticketPast, ticketUpcoming, ticketdataTable])

  return (
    <div className='py-6 md:py-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8'>
        
        {
          data && data.map((item, index) => {
            return(
              <div key={index}>
                <TicketCard date={item.purchase_time} title={item.event?.name || `Call for Designers...`} image={item.event?.image} ticketHandler={ticketHandler} number={`${item?.id}`}/>
              </div>
            )
          })
        }
        {/* <TicketCard ticketHandler={ticketHandler} number="2"/>*/}
    </div>
  )
}

export default Tickets