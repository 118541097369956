import React from 'react';
import { motion } from 'framer-motion';

const LeadershipCard = ({ id, title, description, img, imgAlt }) => (
    <motion.div
        key={id}
        initial={{ y: 100, }}
        whileHover={{scale: 1.04}}
        whileInView={{ y: 0, transition: { duration: .6, ease: 'easeIn',}}}
        className="flex flex-col items-center justify-center w-full md:w-1/4 py-8 lg:py-8 xl:py-16 px-2 lg:px-0 bg-white gap-1 xs:gap-2 xl:gap-4 rounded-lg hover:shadow-round shadow-right transition cursor-pointer">
        <img src={img} alt={imgAlt} className='w-16 lg:w-fit' />
        <h3 className='text-[#3B3A40] font-bold text-center text-xxs xs:text-sm lg:text-base'>{title}</h3>
        <span className='text-[#64626A] text-center text-xxs md:text-sm lg:text-base'>{description}</span>
    </motion.div>
);

export default LeadershipCard;