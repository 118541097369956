import images from "../assets";
import { BookIcon, StartUpIcon, ThreeSquareIcon } from "../components/Reusables/SvgIcons";
import { IoMdMusicalNotes } from "react-icons/io";
import { MdNightlife, MdHealthAndSafety, MdInterests,MdBusiness, MdFoodBank  } from "react-icons/md";
import { GiGlassCelebration } from "react-icons/gi";




export const navItems = [
  {
    id: 1,
    title: "Home",
    path: "/",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  // {
  //   id: 2,
  //   title: "About Us",
  //   path: "/about",
  //   nName: "nav-item",
  //   sName: "sidebar-item"
  // },
  {
    id: 3,
    title: "Browse",
    path: "/browse",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  // {
  //   id: 4,
  //   title: "Contact Us",
  //   path: "/policies",
  //   nName: "nav-item",
  //   sName: "sidebar-item"
  // }
];

export const footerItems = [
  {
    id: 1,
    title: "About Us",
    path: "/about",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 2,
    title: "Contact Us",
    path: "/policies",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 3,
    title: "Term of Use",
    path: "/termsofuse",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 4,
    title: "Privacy Policy",
    path: "/privacypolicy",
    nName: "nav-item",
    sName: "sidebar-item"
  },
];

export const productSectionItems = [
  {
    id: 1,
    icon: <ThreeSquareIcon />,
    title: 'Our products & solutions',
    description: 'Fast and easy to use. Our event app lets you access unlimited event tickets a day!',
  },
  {
    id: 2,
    icon: <StartUpIcon />,
    title: 'Access our daily rates',
    description: 'Fast and easy to use. Our event app lets you access unlimited event tickets a day!',
  },
  {
    id: 3,
    icon: <BookIcon />,
    title: 'Global and local events',
    description: 'Fast and easy to use. Our event app lets you access unlimited event tickets a day!',
  },
];

export const featuredCardsItems = [
  {
    id: 1,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 2,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 3,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 4,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 5,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 6,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 7,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 8,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 9,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 10,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 11,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 12,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 13,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
  {
    id: 14,
    title: 'GTBANK Fashion Week',
    time: 'Thu, Apr 19 · 20.00 Pm',
    location: '35 VI Lagos Island',
    icon: images.eventImg.src,
    iconAlt: images.eventImg.alt,
  },
];



export const ChooseUsCardsItems = [
  {
    id: 1,
    title: 'Music & Performing Arts',
    description: "",
    icon: <IoMdMusicalNotes className='w-4 h-4 sm:w-6 sm:h-6 text-primary-dark'/>
  },
  {
    id: 2,
    title: 'Nightlife & Social Gatherings',
    description: "",
    icon: <MdNightlife className='w-4 h-4 sm:w-6 sm:h-6 text-primary-dark'/>
  },
  {
    id: 3,
    title: 'Cultural & Heritage Celebrations',
    description: "",
    icon:<GiGlassCelebration className='w-4 h-4 sm:w-6 sm:h-6 text-primary-dark'/>
  },
  {
    id: 4,
    title: 'Health & Wellness',
    description: "",
    icon:<MdHealthAndSafety className='w-4 h-4 sm:w-6 sm:h-6 text-primary-dark'/>
  },
  {
    id: 5,
    title: 'Hobbies & Interests',
    description: "",
    icon:<MdInterests className='w-4 h-4 sm:w-6 sm:h-6 text-primary-dark'/>
  },
  {
    id: 6,
    title: 'Business & Professional Development',
    description: "",
    icon: <MdBusiness className='w-4 h-4 sm:w-6 sm:h-6 text-primary-dark'/>
  },
  {
    id: 7,
    title: 'Food, Drink & Culinary Experiences',
    description: "",
    icon:<MdFoodBank className='w-4 h-4 sm:w-6 sm:h-6 text-primary-dark'/>
  }
];
export const LeadershipCardsItems = [
  {
    id: 1,
    title: 'Host',
    description: 'Organize and events',
    img: images.smileImg.src,
    imgAlt: images.smileImg.alt,
  },
  {
    id: 2,
    title: 'Discover',
    description: 'Find your next experience',
    img: images.laptopImg.src,
    imgAlt: images.laptopImg.alt,
  },
  {
    id: 3,
    title: 'Grow without growing pains',
    description: 'Start growing your event business with us',
    img: images.plantImg.src,
    imgAlt: images.plantImg.alt,
  },
];
export const LeadershipCardsImgItems = [
  {
    id: 1,
    title: 'Roy Osborne',
    description: 'CEO and co-founder',
    img: images.leadershipImg1.src,
    imgAlt: images.leadershipImg1.alt
  },
  {
    id: 2,
    title: 'Roy Osborne',
    description: 'CEO and co-founder',
    img: images.leadershipImg2.src,
    imgAlt: images.leadershipImg2.alt
  },
  {
    id: 3,
    title: 'Roy Osborne',
    description: 'CEO and co-founder',
    img: images.leadershipImg3.src,
    imgAlt: images.leadershipImg3.alt
  },
  {
    id: 4,
    title: 'Roy Osborne',
    description: 'CEO and co-founder',
    img: images.leadershipImg4.src,
    imgAlt: images.leadershipImg4.alt
  },
];


