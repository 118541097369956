import React from 'react';
import { CheckCircleIcon } from '../SvgIcons';
import { IoIosRemoveCircleOutline } from 'react-icons/io';
import { toast } from 'react-toastify';

const EventCardToast = ({ message, success }) => {
    const iconComponent = success ? (
        <div className="p-2 rounded-full mr-2 bg-[#D1FADF]">
            <CheckCircleIcon className="" />
        </div>
    ) : (
        <div className="p-2 rounded-full mr-2 bg-danger-200">
            <IoIosRemoveCircleOutline className="text-xl text-white" />
        </div>
    );

    toast[success ? 'success' : 'error'](
        <div className="text-sm font-semibold ml-5">{message}</div>,
        {
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            autoClose: 2000, // Delay of 2 seconds before closing
            icon: iconComponent,
            bodyClassName: 'custom-toast-body',
        }
    );

    return null; // Render nothing in the component's output
};

export default EventCardToast;
