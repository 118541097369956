import React from 'react';
// import { ChooseIcon } from '../SvgIcons';
import { motion } from 'framer-motion';

const ChooseUsCard = ({ id, title, description,icon, x }) => (
    <motion.div
        initial={{ y: 50, opacity: 0.5 }}
        whileInView={{ y: 0, transition: { duration: 0.4, ease: 'easeOut', delay: 0.1 }, opacity: 1 }}
        whileHover={{ scale: 1.03 }}
        key={id}
        className="flex flex-col items-center sm:items-center sm:flex-row py-3 xl:pr-16 pl-2 bg-white gap-4 sm:gap-2 rounded-xl hover:shadow-round drop-shadow-md transition cursor-pointer"
    >
        <div className="mx-2 flex sm:block pr-4 sm:p-0">
            <div className="w-fit p-4 bg-[#F0D7E1] rounded-full">
                {/* <ChooseIcon className='w-4 h-4 sm:w-6 sm:h-6' /> */}
                {icon}
            </div>
        </div>

        <h3 className="text-text-color-400 text-xl sm:text-2xl font-[600] text-center sm:text-start">{title}</h3>
        {/* <div className="flex flex-col px-2 gap-2 sm:gap-4"> */}
        {/* <div className="px-2"> */}
            {/* <h3 className="text-text-color-400 text-xl sm:text-2xl font-[600] text-center sm:text-start">{title}</h3> */}
            {/* <span className="text-sm sm:text-lg text-text-color-300 text-center sm:text-start">{description}</span> */}
        {/* </div> */}
    </motion.div>
);

export default ChooseUsCard;