import React, { useEffect } from 'react'
import { useState } from 'react';
import Select from 'react-select';

function ReactSelect(props) {
  const [selectOption, setSelectOption] = useState(null)

  const {input} = props

  // useEffect(() => {
  //   input(selectOption)
  // }, [input, selectOption])

 
  function customTheme(theme){
    return {
      ...theme,
      colors: {
        ...theme.colors,
        
        primary:'#FD015C'
      }
    }
  }
  // primary25: '#F2F2F2',
  return (
    <Select 
    onChange={(selectOption) => input(selectOption)} 
    defaultValue={props.value}
    options={props.options} 
    isMulti={false||props.isMulti}
    isSearchable 
    className='' 
    placeholder={props.placeholder} theme={customTheme}/>
  )
}

export default ReactSelect