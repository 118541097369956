import React, { useEffect, useState } from 'react';
import * as Icons from 'react-icons/fa';
import './browser.css';
// import { featuredCardsItems } from '../../../constants';
import FeaturedCard from '../../Reusables/Cards/FeaturedCard';
import { motion } from 'framer-motion';
import Button from '../../../UI_Elements/Button/Button';
import moment from 'moment';
import images from '../../../assets';

import { useEvent } from '../../../api/events-hook';
import Loader from '../../../UI_Elements/loader/Loader';


const Index = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [pageDetails, setPageDetails] = useState({
        pageSize:10,
        pageNumber:1
    })
    // const [event, setEvent] = useState(null)
    const [eventList, setEventList] = useState(null)
    

    const tabsData = [
        {
            label: "Events",
            content: "This is the content for tab 1.",
            eventType:"event"
        },
        {
            label: "Locations",
            content: "Currently not available",
            eventType:"location"
        },
    ];

    const {statusLoading, getEvent} = useEvent()

    useEffect(()=>{
        let url = "LIST-UPCOMING-EVENT-PAGINATED"
        
        getEvent(url, `?page_size=${pageDetails.pageSize}&page_number=${pageDetails.pageNumber}&event_type=${activeTab === 0 ? "event" : "location"}`)
        .then((res) => {
            setEventList([])
            if(res.events.length !== 0){
                for(let file of res.events){
                    setEventList((eventList)=>[...eventList, {
                        "id":file.id,
                        "title": file?.name,
                        "time": moment(file.start_time).format('ddd, MMM DD · HH:mm:ss A'),
                        "location": `${file.address}, ${file.state}`,
                        "icon": images.eventImg.src,
                        "iconAlt": images.eventImg.alt,
                        "image":file?.image,
                        "event_type":file?.event_type
                    }])
                }
            }
            


        }).catch((error) => {
            console.log(error)
        })

    },[getEvent, pageDetails.pageSize, pageDetails.pageNumber,activeTab])

    const searchHandler = () =>{
        getEvent("SEARCH-EVENT", `?search_term=${searchValue}&event_type=${activeTab === 0 ? "event" : "location"}`).then((res) => {
            setEventList([])
            if(res.search_results.length !== 0){
                for(let file of res.search_results ){
                    setEventList((eventList)=>[...eventList, {
                        "id":file.id,
                        "title": file.name,
                        "time": moment(file.start_time).format('ddd, MMM DD · HH:mm:ss A'),
                        "location": `${file.address}, ${file.state}`,
                        "icon": images.eventImg.src,
                        "iconAlt": images.eventImg.alt,
                        "image":file?.image,
                        "event_type":file?.event_type
                    }])
                }
            }
            


        }).catch((error) => {
            console.log(error)
        })
    }

    const loadMoreHandler = () => {
        setPageDetails((prevData) => {
            return {
                ...prevData,
                pageNumber: prevData.pageSize + 1
            }
        })
    }

    

    const handleInputChange = (event) => {
        // Update the state with the current input value
        setSearchValue(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchHandler()
        }
      };

    return (
        <>
            <div className='browser-bg-img relative pb-4 xl:pb-12 mt-16'>
                <motion.div
                    initial={{ y: -20, opacity: 0 }}
                    whileInView={{ y: 0, transition: { duration: 1, ease: 'easeOut', delay: 0.1 }, opacity: 1 }}
                    viewport={{ once: true }}
                    class="relative flex justify-center -mt-28 mx-auto px-8 xs:px-16 md:px-32 lg:px-64"
                >
                    <input type="text" 
                     onInput={handleInputChange} // Call the handleInputChange function on input change
                     value={searchValue} // Bind the value of the inp
                    class="px-4 py-2 xl:py-4 rounded-l-md focus:outline-none focus:border-transparent w-full" 
                    onKeyDown={handleKeyDown}
                    placeholder="Search event here" />
                    <button class="px-3 bg-white text-black-alt rounded-r-md">
                        <Icons.FaSearch class="text-lg" onClick={searchHandler}/>
                    </button>
                </motion.div>

                <ul className="flex space-x-6 mt-2 md:mt-4 xl:mt-12 w-fit mx-auto">
                    {tabsData.map((tab, idx) => (
                        <li key={idx}>
                            <button
                                className={`cursor-pointer text-base md:text-xl border-b border-transparent ${activeTab === idx ? "text-primary font-bold" : "text-primary/75"} relative`}
                                onClick={() => setActiveTab(idx)}
                            >
                                {tab.label}
                                <span className={`h-[1px] inline-block bg-primary hover:bg-primary absolute left-[.3rem] -bottom-[.3rem] group-hover:w-full transition-[width] ease duration-300 ${activeTab === idx ? 'w-[80%]' : 'w-0'}`}>&nbsp;
                                </span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            

            {/* Event UI */}
            <section className="px-14 pb-24 sm:pb-32 mt-6">
                <div className="flex flex-col items-center">
                    <div className={`py-4 transition ${activeTab === 0 ? "block" : "hidden"}`}>
                        {statusLoading && (
                            <Loader/>
                        )}
                        {eventList !== null && (
                        <>
                        <div className="grid slg:grid-cols-2 gap-6 mt-2">
                            { eventList.map((card, idx) => (
                                <FeaturedCard key={idx} 
                                x={card.id % 2 === 0 ? 100 : -100} 
                                {...card} 
                                 />
                            ))}
                           
                        </div>
                        <div className="flex justify-center w-full items-center my-16">
                            {eventList.length === pageDetails.pageSize && 
                            <Button onClick={loadMoreHandler} 
                            className="!px-10 sm:!px-20 py-3 !rounded-sm bg-primary-dark hover:scale-105 hover:drop-shadow-xl transition text-xs sm:text-base" 
                            textWhite shadow>Load More</Button>}
                        </div>
                        </>)}
                        {eventList ===  null && 
                            <div className="flex justify-center w-full col-span-1 md:col-span-2">
                                <p className="text-center mt-8 font-semibold">No Events Available</p>
                            </div>}
                    </div>

                    {/* Location UI */}
                    <div className={`py-4 ${activeTab === 1 ? "block" : "hidden"}`}>
                        {statusLoading && (
                            <Loader/>
                        )}

                        {eventList !== null && (
                        <>
                        <div className="grid slg:grid-cols-2 gap-6 mt-2">
                            { eventList.map((card, idx) => (
                                <FeaturedCard key={idx} {...card} x={card.id % 2 === 0 ? 100 : -100} />
                            ))}
                           
                        </div>

                        <div className="flex justify-center w-full items-center my-16">
                            {eventList.length === pageDetails.pageSize && 
                            <Button onClick={loadMoreHandler} 
                            className="!px-10 sm:!px-20 py-3 !rounded-sm bg-primary-dark hover:scale-105 hover:drop-shadow-xl transition text-xs sm:text-base" 
                            textWhite shadow>Load More</Button>}
                        </div>
                        </>
                        )}
                        
                        {/* If there is no location */}
                        {eventList ===  null && 
                            <div className="flex justify-center w-full col-span-1 md:col-span-2">
                                <p className="text-center mt-8 font-semibold">No Locations Available</p>
                            </div>}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Index;