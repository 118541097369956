import React from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { formatFileSize } from '../../../../util/functions'

function PhotoCard({file, deleteImageHandler}) {
  
  return (
    <div className=' mt-2'>
        <div className='flex justify-between items-start'>
              <div>
                <p className='text-sm text-primary-black-70 font-semibold'>{file[0]?.name || "re-upload image"}</p>
                <p className='text-sm text-primary-black-70'>{formatFileSize(file[0]?.size) || "re-upload image"}</p>
              </div>

              <FiTrash2 className="cursor-pointer" onClick={()=>{deleteImageHandler(0)}}/>
          </div>

          {/* <div className='flex items-center mt-2'>

            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div className="bg-primary h-2.5 rounded-full" style={{width: "45%"}}></div>
            </div>

            <p className='mx-3 '>45%</p>
          </div> */}
    </div>
  )
}

export default PhotoCard