
import LandingPageMain from "../../components/PageFragments/ticketValidationPage/Index";

const Index = () => {

  return (
    <LandingPageMain />
  );
};

export default Index;