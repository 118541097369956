export const images = {
    youtechLogo: {
        src: require('./youtech-icon.png'),
        alt: 'youtech-logo',
    },
    logo: {
        src: require('./logo-small.png'),
        alt: 'tickit-logo',
    },
    appleLogo: {
        src: require('./mobile-log.png'),
        alt: 'apple-logo',
    },
    googlePlayLogo: {
        src: require('./google-play-logo.png'),
        alt: 'google-play-logo',
    },
    appleLogoWhite: {
        src: require('./mobile-logo-white.png'),
        alt: 'apple-logo-white',
    },
    cityImage: {
        src: require('./tickit-bg.png'),
        alt: 'city-image',
    },
    featuredCard: {
        src: require('./feature-card-img.png'),
        alt: 'feature-card-alt',
    },
    radial: {
        src: require('./radial.png'),
        alt: 'radial-alt',
    },
    radial2: {
        src: require('./radial2.png'),
        alt: 'radial-alt',
    },
    iphone11a: {
        src: require('./iPhone-11a.png'),
        alt: 'iphone-11',
    },
    iphone11b: {
        src: require('./iPhone 11b.png'),
        alt: 'iphone 11',
    },
    iphone11c: {
        src: require('./iPhone 11c.png'),
        alt: 'iphone-11',
    },
    iphone11d: {
        src: require('./iPhone 11d.png'),
        alt: 'iphone-11',
    },
    graphic1: {
        src: require('./graphic-1.png'),
        alt: 'graphic-1',
    },
    graphic2: {
        src: require('./graphic-2.png'),
        alt: 'graphic-2',
    },
    graphic3: {
        src: require('./graphic-3.png'),
        alt: 'graphic-3',
    },
    graphic6: {
        src: require('./graphic-6.png'),
        alt: 'graphic-6',
    },
    graphic7: {
        src: require('./graphic-7.png'),
        alt: 'graphic-7',
    },
    getStartedImg1: {
        src: require('./getstarted-img.png'),
        alt: 'getstarted-img-1',
    },
    getStartedImg2: {
        src: require('./getstarted-img2.png'),
        alt: 'getstarted-img-2',
    },
    aboutHeroImg: {
        src: require('./about-hero-img.png'),
        alt: 'about-hero-img',
    },
    smileImg: {
        src: require('./smile.png'),
        alt: 'smile-img',
    },
    laptopImg: {
        src: require('./laptop.png'),
        alt: 'laptop-img',
    },
    plantImg: {
        src: require('./plant.png'),
        alt: 'plant-img',
    },
    leadershipImg1: {
        src: require('./leadership-img1.png'),
        alt: 'leadership-img',
    },
    leadershipImg2: {
        src: require('./leadership-img2.png'),
        alt: 'leadership-img',
    },
    leadershipImg3: {
        src: require('./leadership-img3.png'),
        alt: 'leadership-img',
    },
    leadershipImg4: {
        src: require('./leadership-img4.png'),
        alt: 'leadership-img',
    },
    browseHeroImg: {
        src: require('./browse-hero-img.png'),
        alt: 'browse-hero-img',
    },
    eventImg: {
        src: require('./event-image.png'),
        alt: 'event-image',
    },
    aboutImg1: {
        src: require('./about-img-1.png'),
        alt: 'about-image',
    },
    aboutImg2: {
        src: require('./about-img-2.png'),
        alt: 'about-image',
    },
    aboutImg3: {
        src: require('./about-img-3.png'),
        alt: 'about-image',
    },
    aboutImg4: {
        src: require('./about-img-4.png'),
        alt: 'about-image',
    },
    aboutImg5: {
        src: require('./about-img-5.png'),
        alt: 'about-image',
    },
    aboutImg6: {
        src: require('./about-img-6.png'),
        alt: 'about-image',
    },
    aboutCircleImg1: {
        src: require('./about-circle-1.png'),
        alt: 'about-circle-image',
    },
    aboutCircleImg2: {
        src: require('./about-circle-2.png'),
        alt: 'about-circle-image',
    },
    aboutCircleImg3: {
        src: require('./about-circle-3.png'),
        alt: 'about-circle-image',
    },
    aboutCircleImg4: {
        src: require('./about-circle-4.png'),
        alt: 'about-circle-image',
    },
    aboutCircleImg5: {
        src: require('./about-circle-5.png'),
        alt: 'about-circle-image',
    },
    aboutCircleImg6: {
        src: require('./about-circle-6.png'),
        alt: 'about-circle-image',
    },
    aboutCircleImg7: {
        src: require('./about-circle-7.png'),
        alt: 'about-circle-image',
    },
    aboutCircleImg8: {
        src: require('./about-circle-8.png'),
        alt: 'about-circle-image',
    },
    loaderImg: {
        src: require('./loader-img.png'),
        alt: 'loader-img',
    },
    passwordIcon: {
        src: require('./password-icon.png'),
        alt: 'password',
    },
    successIcon: {
        src: require('./success-icon.png'),
        alt: 'success',
    },
    mailIcon: {
        src: require('./success-icon.png'),
        alt: 'success',
    },
    profileImg: {
        src: require('./profile-img.png'),
        alt: 'success'
    },
    ticketFlyer: {
        src: require('./ticket-flyer.png'),
        alt: 'ticket-flyer'
    },
    dropImageIcon: {
        src: require('./drop-image-icon.png'),
        alt: 'drop'
    },
    ticketCode: {
        src: require('./ticketcode.png'),
        alt: 'ticket'
    },
    calendar: {
        src: require('./calendar.png'),
        alt: 'calendar'
    },
    map: {
        src: require('./map-pin.png'),
        alt: 'map-pin'
    }
};

export default images;
