import React from 'react'

function HOC({children}) {
  return (
    <div className='auth_bg flex justify-center pt-16 mt-8 '>
        {children}
    </div>
  )
}

export default HOC