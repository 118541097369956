import React from 'react';
import images from '../../../assets';
import AnimatedText1 from '../../Reusables/Texts/AnimatedText1';
import AnimatedAboutImgs from './AnimatedAboutImgs';

const HeroSection = () => {
    return (
        <section className='bg-white xl:pb-[32rem] pt-[6.5rem] pb-5 px-6 md:px-12 lg:px-20'>
            <AnimatedText1
                text="Bringing the world together through live experiences"
                className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-[900] text-text-color-400 max-w-sm sm:max-w-md md:max-w-lg lg:max-w-2xl xl:max-w-3xl xl:leading-[1.2] text-center mx-auto"
            />
            <AnimatedAboutImgs />
            <img src={images.aboutHeroImg.src} alt={images.aboutHeroImg.alt} className='mx-auto mt-10 block xl:hidden' />
        </section>
    );
};

export default HeroSection;