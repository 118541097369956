import React from 'react'
import Loader from '../../UI_Elements/loader/Loader'
import Modal from '../../UI_Elements/Modal/Modal'

function AuthLoading(props) {
  return (
    <Modal show={props.loading} fade loading>
            <div className='flex flex-col justify-center items-center text-center'>
                <Loader/>
                <h3 className='mb-2 mt-4 font-semibold text-primary-black'>Loading...</h3>
                <p className='text-primary-black-70 text-sm'>Please wait we are verifiying your email</p>
            </div>
    </Modal>
  )
}

export default AuthLoading