import React from 'react';
import { motion } from 'framer-motion';
import images from '../../../assets';
import AnimatedText2 from '../../Reusables/Texts/AnimatedText2';

const GetStartedSection = () => {
    return (
        <section className='bg-white pt-12 pb-12 px-8 sm:32 h-auto sm:h-screen'>
            <div className="mx-auto sm:py-20 lg:py-8 py-20 p-4 sm:px-16 slg:py-0 flex w-fit slg:w-[80%] h-[35vh] sm:h-[70vh] lg:h-[80vh] rounded-md sm:rounded-3xl bg-text-color-400 relative overflow-hidden drop-shadow-lg">
                <div className="flex slg:mt-1 flex-col justify-around items-center sm:items-start z-3 h-full sm:h-fit py-6">
                    <AnimatedText2
                        text="The all-in-one event and ticketing platform for all"
                        className="text-base xs:text-xl sm:text-3xl slg:!text-[2.5rem] max-w-xs sm:max-w-lg slg:!max-w-sm !leading-[1.2] font-bold text-white text-center xmd:text-start"
                    />

                    <p className='text-white text-xs sm:text-base slg:text-xl max-w-xs sm:max-w-lg slg:!max-w-sm mt-3 text-center xmd:text-start'>Tickit is the leading event ticketing platform trusted by organizers and attendees around the world.
                    </p>
                    {/* <div className='flex items-center w-fit mx-auto slg:mx-0 lg:justify-start gap-4 mt-3 sm:mt-9' >
                        <img src={images.appleLogoWhite.src} alt={images.appleLogoWhite.alt} className="w-20 sm:w-32 slg:w-44" />
                        <img src={images.googlePlayLogo.src} alt={images.googlePlayLogo.alt} className="w-24 sm:w-36 slg:w-48 rounded-lg" />
                    </div> */}
                </div>


                <motion.img
                    initial={{ x: 200, opacity: 0 }}
                    whileInView={{ x: 0, transition: { duration: 1, ease: 'easeOut', delay: 0.4 }, opacity: 1 }}
                    src={images.radial2.src}
                    alt={images.radial.alt}
                    className='absolute -bottom-20 left-[30rem] hidden lg:block'
                />
                <motion.img
                    initial={{ x: 200, }}
                    whileInView={{ x: 0, transition: { duration: 1, ease: 'easeOut', delay: 0.6 }}}
                    src={images.getStartedImg1.src}
                    alt={images.getStartedImg1.alt}
                    className='absolute bottom-0 left-[35rem] z-10 hidden lg:block'
                />
                <motion.img
                    initial={{ x: 200,}}
                    whileInView={{ x: 0, transition: { duration: 1, ease: 'easeOut', delay: 0.8 } }}
                    src={images.getStartedImg2.src}
                    alt={images.getStartedImg2.alt}
                    className='absolute bottom-0 left-[50rem] hidden lg:block'
                />
            </div>
        </section>
    );
};

export default GetStartedSection;