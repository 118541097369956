import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';



import Header2 from './components/Reusables/Headers/Header2';
import Footer1 from './components/Reusables/Footers/Footer1';
import ScrollToTopButton from './components/Reusables/ScrollToTopButton';

//pages
import pages from './pages'
import auth from './auth';
import dashboard from './dashboard'

// installed packages
import { ToastContainer } from 'react-toastify';

//custom packages css
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

// custom hooks
import { useAuth } from './hooks/auth-hook';
import { AuthContext } from './context/auth-context';
import HeaderDashboard from './components/Reusables/Headers/HeaderDashboard';
import { useProfile } from './api/profile-hook';
import { DataContext } from './context/DataContext';
import Loader from './UI_Elements/loader/Loader';
import ScrollToTop from './util/ScrollToTop';

const App = () => {
  
  const { token, login, logout } = useAuth();

  const isToken = useMemo(() =>{
    if(token){
      return true
    }
    else{
      return false
    }
  },[token])

  
  return(
    <AuthContext.Provider
            value={{
            isLoggedIn: !!token,
            token:token,
            login: login,
            logout: logout
    }}>

        <Router>
          <AppContent  isToken={isToken} />
        </Router>

    </AuthContext.Provider>
  )
}

const AppContent = ({isToken}) => {
  const pathname = useLocation().pathname;
  const showScrollToTopButton = pathname === '/' || '/about' || pathname.includes('browse');

  const [userData, setUserData] = useState({})

  const {userProfile, profileLoading, profileData} = useProfile()
  const updateData = useCallback((data) => {
    setUserData({
      ...userProfile,
      ...data
    })

  },[userProfile])

  useEffect( () => {

    const fetchData = async (email) => {
      
      await profileData(email).then(res => setUserData(res))
    }

    
    if(localStorage.getItem('profileData') && isToken){
      const data = JSON.parse(localStorage.getItem('profileData'))
      
      fetchData(data?.user?.email_address); 
    }
    
    
  }, [ profileData, isToken]); 

  useEffect(() => {
    
    setUserData(userProfile)
  },[userProfile, isToken])

  return (
    <>
      <ToastContainer />
      {!isToken && (
        <>
        <ScrollToTop>
        {showScrollToTopButton && <ScrollToTopButton />}
        <Header2 />
        <Routes>
          <Route path="/" element={<pages.Home />} />
          <Route path="/about" element={<pages.About />} />
          <Route path="browse/:eventId" element={<pages.Events />} />
          <Route path="/browse" element={<pages.Browse />} />
          <Route path="/ticket-validation" element={<pages.ticketValidation/>} />
          <Route path='newpassword' exact element={< auth.NewPassword />}/>
          <Route path='forgotpassword' exact element={< auth.Forgotpassword />}/>
          <Route path='verification' exact element={< auth.Verification />}/>
          <Route path='/createpassword' exact element={< auth.CreatePassword />}/>
          <Route path='/signup' exact element={< auth.Signup />}/>
          <Route path='/login' exact element={< auth.Login />}/>
          <Route path="*" exact element={<pages.Home />}/>
        </Routes>
        {!isToken&& <Footer1 />}
        </ScrollToTop>
        </>
      )}
      {isToken && (
        <>
        
        {showScrollToTopButton && <ScrollToTopButton />}
        <DataContext.Provider value={
        {
          userData:userData,
          updateData:updateData
        }
      }>
          <ScrollToTop>
          {profileLoading && <Loader title="Loading... Please wait"/>}
          <HeaderDashboard />
          <Routes>
            <Route path="/" element={<pages.Home />} />
            <Route path="/about" element={<pages.About />} />
            <Route path="browse/:eventId" element={<pages.Events />} />
            <Route path="/browse" element={<pages.Browse />} />
            <Route path="/dashboard" element={<dashboard.DashboardOverview/>} />
            <Route path="/settings" element={<dashboard.Settings/>} />
            <Route path="/ticket/:id" element={<dashboard.Ticket/>} />
            <Route path="/ticket-validation" element={<pages.ticketValidation/>} />
            <Route path='/phone-verification' element={< auth.Verification />}/>
            <Route path="*" exact element={<pages.Home />}/>
          </Routes>
          <Footer1 />
          </ScrollToTop>
        </DataContext.Provider>
        </>
      )}
      
    </>
  );
};

export default App;