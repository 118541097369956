import React from 'react';
import AnimatedText3 from '../../Reusables/Texts/AnimatedText3';

const AboutUsSection = () => {
    return (
        <section className='bg-text-color-400 overflow-hidden pb-20 lg:pb-40'>
            <div className="pt-3 flex w-[100%] justify-end">
                <AnimatedText3
                    text="About Us"
                    className="text-[4rem] xs:text-[5.5rem] md:text-[8rem] lg:!text-[10.5rem] font-[900] text-white/30 text-end uppercase -mr-16"
                />
            </div>
            <div className="grid lg:grid-cols-2 gap-2 sm:gap-4 text-sm xs:text-base sm:text-lg md:text-xl lg:text-2xl text-text-color-300 px-4 sm:px-6 md:px-12 lg:px-12 xl:px-20 mt-2 sm:mt-5 lg:mt-10">
                <p className='leading-[1.8]'>Tickit is a global self-service ticketing platform for live experiences that allows anyone to create, share, find and attend events that fuel their passions and enrich their lives. </p>
                <p className='leading-[1.8]'>From music festivals, marathons, conferences, community rallies, and fundraisers, to gaming competitions and air guitar contests. Our mission is to bring the world together through live experiences.</p>
            </div>
        </section>
    );
};

export default AboutUsSection;