import React from 'react';
import images from '../../../assets';
import "./LoadingSpinner.css"

const AnimatedLoader = () => {
    return (
        <img src={images.loaderImg.src} alt={images.loaderImg.alt} className='loading_spin' />
    );
};

export default AnimatedLoader;
