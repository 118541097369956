import React from 'react'
import { BsArrowRightShort } from 'react-icons/bs'

function Navigation({navText,clickNavHandler, nav, tab }) {
  return (
    <button onClick={() => clickNavHandler(nav)} 
    className={`${nav === tab ? "bg-primary text-white" :"bg-white text-black-text"} py-3 px-4 w-full flex justify-between items-center`}>
        <p className='text-left'>{navText}</p>
        <BsArrowRightShort className={`${nav === tab ? "bg-primary text-white" :"bg-white text-black-text"} text-lg`}/>
    </button>
  )
}

export default Navigation