import { useHttpClient } from '../hooks/less-http-hook'
import { AuthContext } from '../context/auth-context'
import { useContext, useEffect, useState } from 'react'
import { useCallback, useMemo } from 'react';


export const useEvent = () =>{
    const {isLoading, error, sendRequest} = useHttpClient();

    const [statusLoading, setStatusLoading] = useState(isLoading)

    const eventEndpoints = useMemo(() => {
        return {
          'CREATE-EVENT': '/event/create',
          'CREATE-DRAFT': '/event/create-draft',
          'UPDATE-EVENT': '/event/update',
          'LIST-EVENT': '/event/list-all',
          'LIST-EVENT-PAGINATED': '/event/list-all-paginated',
          'LIST-UPCOMING-EVENT': '/event/list-upcoming',
          'LIST-UPCOMING-EVENT-PAGINATED': '/event/list-upcoming-paginated',
          'LIST-PAST-EVENT-PAGINATED': '/event/list-past-paginated',
          'EVENT-DETAILS': '/event/details',
          'SEARCH-EVENT': '/event/search'
        };
      }, []);

    useEffect(() => {
        setStatusLoading(isLoading)
    },[isLoading])
    

    const auth = useContext(AuthContext)

    const postEvent = useCallback( async(id,data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${eventEndpoints[id]}`, "POST", data, {});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[ error, sendRequest, eventEndpoints])

    const updateEvent = useCallback( async(id,data) => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${eventEndpoints[id]}`, "PATCH", data, {Authorization: 'Bearer '+auth.token});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[auth.token, error, sendRequest, eventEndpoints])

    const getEvent = useCallback( async(id,params='') => {

        try {
            const responseData = await sendRequest(`${process.env.REACT_APP_API_CALL}${eventEndpoints[id]}${params}`, "GET", null, {});
            return responseData.data
        
        } catch (err) {
            console.log(error)
        }
    },[ error, sendRequest, eventEndpoints])

    

    return {statusLoading, postEvent, updateEvent, getEvent}
}