import React from 'react';
import * as Icons from 'react-icons/fa';
import './firstSection.css';
import AnimatedText1 from '../../Reusables/Texts/AnimatedText1';
const HeroSection = () => {

    return (
        <section className='bg-img relative pb-16 lg:pb-[32rem] pt-20 sm:pt-32 lg:!pt-0 bg-white mt-16 px-6 xs:px-20 sm:px-48'>
            <div className="xmd:!w-[26rem] flex flex-col gap-6 lg:gap-8 bg-[#ffffff95] mt-6 sm:mt-0 py-12 lg:py-10 px-5 sm:px-8 mx-auto relative lg:absolute lg:top-28 lg:left-32 rounded-lg">

                <AnimatedText1
                    text="Looking For Something New?"
                    className='text-3xl sm:text-4xl xl:text-5xl text-text-color-400 font-[800] !leading-[1.3]'
                />
                {/* <div className="relative flex">
                    <input type="text" className="px-4 py-2 lg:py-3 rounded-l-md focus:outline-none focus:border-transparent w-[80%] text-sm sm:text-base" placeholder="Search event here" />
                    <button className="px-3 bg-white text-black-alt rounded-r-md">
                        <Icons.FaSearch className="text-base" />
                    </button>
                </div> */}
            </div>
        </section>
    );
};

export default HeroSection;