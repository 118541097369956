import React from 'react'
import HistoryTable from './components/HistoryTable'

function History() {
  return (
    <div className="p-6 pb-12">
        <HistoryTable/>
    </div>
  )
}

export default History