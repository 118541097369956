import React from 'react'

function BoardCard({selected, amount, description, onClick}) {
  return (
    <button className={`flex flex-col items-center`} onClick={onClick}>
        <div className={`${selected ? "bg-circle-gray" : "bg-primary"} p-2 px-3 text-sm font-semibold rounded-full`}>
            <p 
            className={`${selected ? "text-black-text font-light  " : "font-semibold text-white"} text-lg `}>
                {amount}
            </p>
        </div>

        <p 
        className={`${selected ? "text-black-text font-light" : "text-primary font-medium"} text-sm x mt-2`}>
            {description}
        </p>
    </button>
  )
}

export default BoardCard