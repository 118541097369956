export const stringToJson = (inputString) =>{

    if(inputString === null || inputString === "undefined" || inputString.length === 0 ){
        return {}
    }
    // Replace single quotes with double quotes and parse as JSON
    const jsonString = inputString.replace(/'/g, '"');
    const jsonObject = JSON.parse(jsonString);

    return jsonObject
}

export function createNumberList(n) {

    if (typeof n !== 'number' || isNaN(n)) {
        return [];
      }

    const numberList = [];
    for (let i = 0; i <= n; i++) {
      numberList.push(i);
    }
    return numberList;
  }


  export function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
  }
  


  export function abvNum (n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  }

  export function formatFileSize(sizeInBytes) {
    const units = ["B", "KB", "MB", "GB"];
    let index = 0;
    while (sizeInBytes >= 1024 && index < units.length - 1) {
      sizeInBytes /= 1024;
      index++;
    }
    return `${sizeInBytes?.toFixed(2) || NaN} ${units[index]}`;
  }
  
  // Function to save data to session storage
  export const saveToSessionStorage = (key, value) => {
    sessionStorage.setItem(key, value);
  };
  
  // Function to remove data from session storage
  export const removeFromSessionStorage = (key) => {
    sessionStorage.removeItem(key);
  };

  export function getHtmlText(html){
    var divContainer= document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
}