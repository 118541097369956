import React, { useEffect, useState } from 'react'
import BreadCrumbNavigation from '../BreadCrumbNavigation'
import images from '../../../assets'

import "./ticket.css"
import { useNavigate, useParams } from 'react-router-dom'
import RefundModal from './components/RefundModal'
import { useTicket } from '../../../api/ticket-hook'
import moment from 'moment'
import QRCode from "react-qr-code";

import { number_format } from '../../../util/functions'

function Index() {

    const history = useNavigate()

    const {getTicket} = useTicket()

    const {id} = useParams()

    const [modal, setmodal] = useState(false)
    const [data, setData] = useState()

    const showModalHandler = () =>{
        setmodal(!modal)
    }

    useEffect(()=>{
        const fetchData = () => {
          getTicket("TICKETS-DETAILS", `?ticket_id=${id}`).then((res) => {
            
            setData(res.ticket || [])
        
            }).catch((error) => {
                console.log(error)
            })
        
        }
    
        fetchData()
    
    
        },[getTicket, id])

        const baseUrl = window.location.protocol + "//" + window.location.host;
  return (
    <>
        <RefundModal show={modal} onCancel={showModalHandler} closeButton ticketId={id || ""} userId={data?.owner?.id || ""}/>
        <BreadCrumbNavigation path="/ticket" nav="Ticket"/>

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-8'>
            <div className='bg-white ticket-bg p-6 md:px-8 md:col-span-2 '>
                <div className='flex justify-center items-center flex-col'>
                    <h2 className='text-black-text font-semibold text-xl mb-4'>Ticket Receipt</h2>
                    {/* <img src={images.ticketCode["src"]} alt={images.ticketCode["alt"]} className='w-32 lg:w-40'/> */}
                    <div style={{ height: "auto", margin: "0 auto",}} className='w-32 lg:w-40'>
                        <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={
                            `${baseUrl}/ticket-validation?eventId=${data?.event?.id || ''}&event_type=${data?.event_type || ''}&ticket_id=${id || ''}`
                        }
                        viewBox={`0 0 256 256`}
                        />
                    </div>
                </div>

                <div className=' my-12 md:my-16 border-t-2 border-dashed border-primary-dark relative'>
                    <div className={`border-2 border-primary-dark text-primary-dark px-6 py-2 
                    w-fit object__center bg-white rounded`}>
                        <p>Ticket 1 of 1</p>
                    </div>
                </div>

                <div className='mb-8'>
                    <p className='font-light text-black-text mb-4'>Name</p>
                    <p className='font-bold text-black-text text-2xl'>{data?.owner?.first_name || ""}<br/>{data?.owner?.last_name || ""}</p>
                </div>
                <div className='mb-8'>
                    <p className='font-light text-black-text mb-3'>Ticket/ seat</p>
                    <p className='font-semibold text-black-text text-base'>{data?.type || "" }</p>
                </div>
                <div className='mb-8'>
                    <p className='font-light text-black-text mb-3'>Event</p>
                    <p className='font-semibold text-black-text text-base lg:w-3/5'>{data?.event?.name || ""}</p>
                </div>

                <div className="flex justify-between items-center mb-8">
                    <div className=''>
                        <p className='font-light text-black-text mb-3'>Date</p>
                        <p className='font-semibold text-black-text text-base mb-2'>{moment(data?.event?.start_time || "").format('ddd, MMM D - h:mma')}</p>
                        <a href={`http://calendar.google.com/?q="${data?.event?.start_time || ""}, ${data?.event?.state || "" }"`} rel="noreferrer" target="_blank" 
                         className='font-semibold text-primary invisible text-base flex items-center gap-2'><img 
                        src={images.calendar["src"]} alt={images.calendar["alt"]}/> Add to Calendar</a>
                    </div>

                    <div className=''>
                        <p className='font-light text-black-text mb-3 text-right'>Location</p>
                        <p className='font-semibold text-black-text text-base mb-2 text-right'>{data?.event?.address || ""}, {data?.event?.state || "" }</p>
                        <a href={`http://maps.google.com/?q="${data?.event?.address || ""}, ${data?.event?.state || "" }"`} rel="noreferrer" 
                        target="_blank" className='font-semibold text-primary  text-base flex items-center gap-2 justify-end'><img 
                        src={images.map["src"]} alt={images.map["alt"]}/> See Map</a>
                    </div>
                </div>

                <div className='mb-8'>
                    <p className='font-light text-black-text mb-3'>Order number</p>
                    <p className='font-semibold text-black-text text-base '>{data?.id || ""}</p>
                </div>

                <div className="flex justify-between items-center mt-16">
                    <div className=''>
                        
                        <p className='font-semibold text-black-text text-2xl mb-2'>TOTAL</p>
                        
                    </div>

                    <div className=''>
                        <p className='font-semibold text-primary text-4xl'>₦{number_format(data?.amount || 0) || 0}</p>
                    </div>
                </div>

            </div>


            <div className='bg-white ticket-bg p-6 w-full lg:w-fit lg:h-fit'>
                <button onClick={() => history("/dashboard")} className='text-white bg-primary w-full py-2 capitalize mb-2 rounded'>
                    Back to Dashboard
                </button>
                <button onClick={showModalHandler} className='text-primary border border-primary w-full py-2 capitalize rounded'>
                    Cancel & Refund
                </button>
            </div>
        </div>
    </>
  )
}

export default Index