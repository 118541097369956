import React from 'react';
import Button from '../../../UI_Elements/Button/Button';
import { motion } from 'framer-motion';
import images from '../../../assets';
import ChooseUsCard from '../../Reusables/Cards/ChooseUsCard';
import { ChooseUsCardsItems } from '../../../constants';
import AnimatedText1 from '../../Reusables/Texts/AnimatedText1';

const ChooseUs = () => {
    return (
        <section className='bg-white pb-20 px-6 md:px-24 relative pt-20 h-auto'>
            <div className="flex flex-col p-2 gap-7">
                <Button className="!px-6 py-8 !rounded-3xl bg-[#F0D7E1] !text-primary-dark w-fit !text-xs sm:!text-lg font-semibold cursor-default" textWhite shadow>READY TO TICKIT?</Button>
                <AnimatedText1
                    className='text-2xl sm:text-4xl slg:text-5xl  max-w-md text-text-color-400 font-bold'
                    text='Tickit now!'
                />
                <p className='text-base sm:text-xl max-w-md text-text-color-300'>Download our App now to enjoy fast and simple ticket purchases for your choice of events at your own comfort.</p>
            </div>
            <div className="xl:flex xl:justify-end mt-12 w-full relative">
                <div className="flex flex-col gap-8 sm:pb-[50rem] xl:pb-0 px-6 sm:px-0">
                    {ChooseUsCardsItems.map((card, idx) => (
                        <ChooseUsCard key={idx} {...card} x={card.id % 2 === 0 ? 100 : -100}  />
                    ))}
                </div>
                <motion.img
                    initial={{ x: -200, opacity: 0 }}
                    whileInView={{ x: 0, transition: { duration: 1, ease: 'easeOut', }, opacity: 1 }}
                    src={images.iphone11d.src}
                    alt={images.iphone11d.alt}
                    className='absolute hidden sm:block sm:top-[54rem] lg:top-[52rem] xl:top-16 left-[1rem] xl:left-5'
                />
                <motion.img
                    initial={{ x: 0, opacity: 0 }}
                    whileInView={{ x: 0, transition: { duration: 1, ease: 'easeOut', delay: 1 }, opacity: 1 }}
                    src={images.graphic6.src}
                    alt={images.graphic6.alt}
                    className='absolute hidden sm:block top-[58.9rem] lg:top-[56.6rem] xl:top-36 left-[9.6rem] xl:left-40'
                />
                <motion.img
                    initial={{ x: 0, opacity: 0 }}
                    whileInView={{ x: 0, transition: { duration: 1, ease: 'easeOut', delay: 1 }, opacity: 1 }}
                    src={images.graphic7.src}
                    alt={images.graphic7.alt}
                    className='absolute hidden sm:block top-[57.3rem] lg:top-[55rem] xl:top-28 left-[21.8rem] xl:left-[22rem]'
                />
            </div>
        </section>
    );
};

export default ChooseUs;