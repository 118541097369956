import React from 'react'
import images from '../../../assets'
import moment from 'moment'

function TicketCard({date, title, ticketHandler, number, image}) {
  return (
    <div onClick={() => ticketHandler(number||0)} className='grid grid-cols-3 rounded cursor-pointer'>
        <div className={`col-span-2 rounded-r-3xl bg-white flex flex-col justify-center 
        items-start p-4 border-r-black-alt border border-dashed`}>
            <h3 className='text-black-text text-2xl font-semibold '>{title}</h3>
            <p className='text-sm text-black-alt font-semibold'>{moment(date).format('ddd, MMM D - h:mma')}</p>
            <button className='text-primary'>See Ticket</button>
        </div>
        <div className='rounded-l-3xl bg-white border-text-color-100 border flex__center'>
            <img src={image || images.ticketFlyer["src"]} alt={images.ticketFlyer["alt"]} className='rounded-l-3xl rounded p-2 w-44 h-28' 
            onError={(ev) => 
            ev.target.src = images.ticketFlyer["src"]}/>
        </div>
    </div>
  )
}

export default TicketCard
