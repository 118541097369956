import React from 'react'
import images from '../../../assets'

import BoardCard from "./BoardCard"
import { Link } from 'react-router-dom'

function Board({ticketAll = [], ticketPast = [], ticketUpcoming=[], cardHandler, data}) {

  
  return (
    <div className="bg-white py-10 px-12 grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className='flex justify-start items-start '>
            <img src={data?.user?.profile_picture || images.logo["src"]} alt={images.logo["alt"]} 
            className="mr-4 h-20 w-20 rounded-full" 
            onError={(ev) => {
              ev.target.src = images.logo["src"]
            }}/>
            <div>
              <h3 className="font-bold text-black-text text-3xl">{data?.user?.first_name || ""} {data?.user?.last_name || ""}</h3>
              <p className='text-black-alt mb-4'>{data.user.email_address}</p>
              <Link to="/settings" className='bg-primary-dark px-6 py-2 text-white rounded'>Edit Profile</Link>
            </div>
        </div>

        {/* <div className='flex justify-between items-center flex-wrap gap-4'> */}
        <div className='grid grid-col-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
          <div className='flex justify-center items-center flex-wrap gap-4'>
            <BoardCard description={"Upcoming Event"} amount={`${ticketUpcoming?.length || 0}`} selected={true} 
            onClick={() => cardHandler("upcoming")}/>
            
          </div>
         
          <div className='flex justify-center lg:justify-between items-center flex-wrap gap-4'>
            <div className='hidden lg:block h-16 lg:h-20' style={{
              borderLeft: "3px solid #D0D5DD"
            }}></div>
            <BoardCard description={"Past Event"} amount={`${ticketPast?.length || 0}`} selected={true} 
            onClick={() => cardHandler("past")}/>
            <div className='hidden lg:block h-16 lg:h-20' style={{
              borderLeft: "3px solid #D0D5DD"
            }}></div>
          </div>
         
          {/* <div className='flex justify-center items-center flex-wrap gap-4'>
            <BoardCard description={"Saved Event"} amount={"0"} selected={true} 
            onClick={() => cardHandler("saved")}/>
          </div> */}
        </div>
    </div>
  )
}

export default Board