import React from 'react';
import * as Iconbs from "react-icons/bs";
import * as Iconpi from "react-icons/pi";
import { SlSocialTwitter } from 'react-icons/sl';
import { CopyLinkSvgIcon, MessageSvgIcon } from './SvgIcons';

export const WhatsAppSocialIcon = ({ bgClassName, iconClassName }) => {

    return (
        <div
            className={`bg-[#26D367] w-fit p-2 md:p-4 rounded-full ${bgClassName}`}
        >
            <Iconbs.BsWhatsapp className={`text-2xl md:text-3xl text-white ${iconClassName}`} />
        </div >
    );
};

export const InstagramSocialIcon = ({ bgClassName, iconClassName }) => {

    return (
        <div
            className={`bg-gradient-to-br from-purple-600 via-pink-500 to-yellow-400 w-fit p-2 md:p-4 rounded-full ${bgClassName}`}
        >
            <Iconbs.BsInstagram className={`text-2xl md:text-3xl text-white ${iconClassName}`} />
        </div >
    );
};

export const TelegramSocialIcon = ({ bgClassName, iconClassName }) => {

    return (
        <div
            className={`bg-[#27A1FC] w-fit p-2 md:p-4 rounded-full ${bgClassName}`}
        >
            <Iconpi.PiTelegramLogoDuotone className={`text-2xl md:text-3xl text-white ${iconClassName}`} />
        </div >
    );
};

export const MessengerSocialIcon = ({ bgClassName, iconClassName }) => {

    return (
        <div
            className={`bg-gradient-to-r from-blue-500 via-purple-600 to-pink-500 w-fit p-2 md:p-4 rounded-full ${bgClassName}`}
        >
            <Iconpi.PiMessengerLogo className={`text-2xl md:text-3xl text-white ${iconClassName}`} />
        </div >
    );
};

export const TwitterSocialIcon = ({ bgClassName, iconClassName }) => {

    return (
        <div
            className={`bg-[#1DA1F3] w-fit p-2 md:p-4 rounded-full ${bgClassName}`}
        >
            <SlSocialTwitter className={`text-2xl md:text-3xl text-white ${iconClassName}`} />
        </div >
    );
};

export const CopyLinkSocialIcon = ({ bgClassName, iconClassName }) => {

    return (
        <div
            className={`bg-[#E0F6F4] w-fit p-2 md:p-4 rounded-full ${bgClassName}`}
        >
            {/* <SlSocialTwitter className={`text-3xl text-white ${iconClassName}`} /> */}
            <CopyLinkSvgIcon className='!w-7 !h-7 md:w-full md:h-full' />
        </div >
    );
};

export const MessagesSocialIcon = ({ bgClassName, iconClassName }) => {

    return (
        <div
            className={`bg-[#22B943] w-fit p-2 md:p-4 rounded-full ${bgClassName}`}
        >
            <MessageSvgIcon className='!w-7 !h-7 md:w-full md:h-full' />
        </div >
    );
};