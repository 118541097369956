import React from 'react';
import LeadershipCard from '../../Reusables/Cards/LeadershipCard';
import { LeadershipCardsImgItems, LeadershipCardsItems } from '../../../constants';
import AboutImgCard from '../../Reusables/Cards/AboutImgCard';
import AnimatedText3 from '../../Reusables/Texts/AnimatedText3';

const LeadershipTeamSection = () => {
    return (
        <section className='pb-40 sm:pb-32 px-4'>
            <div className="-mt-[3rem] lg:-mt-[6rem] flex flex-col items-center md:items-stretch md:flex-row justify-center gap-3 px-8 xs:px-24 sm:px-44 md:px-0 md:gap-6 xl:gap-8">
                {LeadershipCardsItems.map((card) => (
                    <LeadershipCard key={card.id} {...card} />
                ))}
            </div>

            <div className="flex flex-col items-center mt-24 lg:mt-32 xl:mt-40">
                <AnimatedText3
                    text="Leadership Team"
                    className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-text-color-400"
                />
                <p className='mt-4 text-text-color-300 text-xs md:text-sm lg:text-lg max-w-sm md:max-w-xl lg:max-w-2xl xl:max-w-[46rem] text-center'>Oval’s integrated and automated design empowers you to seamlessly run your business like a pro - from proposal to report season.</p>
            </div>
            <div className="flex justify-center flex-col items-center md:items-start md:flex-row gap-4 md:gap-8 mt-8 lg:mt-16 px-16 sm:px-0">
                {LeadershipCardsImgItems.map((card, idx) => (
                    <AboutImgCard key={card.id} {...card} delay={card.id % 2 === 0 ? .2 : 1} />
                ))}
            </div>
        </section>
    );
};

export default LeadershipTeamSection;