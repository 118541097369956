import React, { useState, useEffect } from 'react';
import Button from '../../../UI_Elements/Button/Button';
import * as Icons from "react-icons/vsc";
import * as Iconh from "react-icons/hi2";
import "./Navbar.css";
// import { navItems } from '../../../constants';
import { TickitIcon } from '../SvgIcons';
import { Link, useLocation } from 'react-router-dom';


const Header2 = () => {
    const [mobile, setMobile] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [navbar, setNavbar] = useState(false);

    const location = useLocation();
    const pathname = location.pathname;

    // Extract the dynamic part from the pathname
    const dynamicPart = pathname.substring(pathname.lastIndexOf('/') + 1);

    // Check if the dynamic part is a number
    const isNumber = !isNaN(dynamicPart) && parseInt(dynamicPart) >= 1;

    const header2NavItems = [
        {
            id: 1,
            title: "Home",
            path: "/",
            pathroute: "/",
            nName: "nav-item",
            sName: "sidebar-item"
        },
        // {
        //     id: 2,
        //     title: "About Us",
        //     path: "/about",
        //     pathroute: "/about",
        //     nName: "nav-item",
        //     sName: "sidebar-item"
        // },
        {
            id: 3,
            title: "Tickets",
            path: "/browse" + (isNumber ? `/${dynamicPart}` : ""),
            pathroute: "/browse",
            nName: "nav-item",
            sName: "sidebar-item"
        },
        // {
        //     id: 4,
        //     title: "Contact Us",
        //     path: "/contactus",
        //     pathroute: "/contactus",
        //     nName: "nav-item",
        //     sName: "sidebar-item"
        // }
    ];

    const setFixedHandler = () => {
        window.scrollY >= 390 ? setNavbar(true) : setNavbar(false);
    };

    window.addEventListener("scroll", setFixedHandler);

    useEffect(() => {
        if (window.innerWidth < 960) {
            setMobile(true);
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 960) {
                setMobile(true);
            } else {
                setMobile(false);
                setSidebar(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div className={`flex ${navbar ? "fixed top-0 w-full backdrop-blur-md bg-white/30 z-40 transition-[0.5]" : "fixed z-40 w-full top-0"} justify-between bg-white drop-shadow-lg items-center px-6 md:px-12 lg:px-12 xl:px-24 py-4 lg:py-6 lg:pt-4`}>
                <Link to="/" className="hover:scale-105 transition-[.3] cursor-pointer">
                    <TickitIcon />
                </Link>

                {!mobile ? (
                    <>
                        <ul className='flex gap-8 '>
                            {header2NavItems.map((item) => {
                                if (item.title === 'Contact Us') {
                                    return (
                                        <li key={item.id} className={`${item.nName} border-b border-transparent hover:border-primary ${pathname === item.path ? 'text-primary border-primary' : 'text-text-color-400'} transition`}>
                                            <a
                                                href="mailto:hello@tickit.io?subject=Making Inquiries at Tickit&body=Hi Tickit"
                                                target="_blank" rel="noopener noreferrer"
                                                className={`${item.nName} text-lg `}>
                                                {item.title}
                                            </a>
                                        </li>
                                    );
                                }
                                return (
                                    <li key={item.id} className={`${item.nName} border-b !font-normal border-transparent hover:border-primary ${pathname === item.path ? 'text-primary border-primary' : 'text-text-color-400'} transition`}>
                                        <Link to={item.pathroute} className={`${item.nName} text-lg`}>{item.title}</Link>
                                    </li>
                                );
                            })}
                        </ul>

                        <div className='flex justify-between text-lg'>
                            <Button to="/signup" className="font-medium bg-transparent text-text-color-400 !py-2 !px-3 hover:text-primary transition" >Sign Up</Button>
                            <Button to="/login" className="font-medium bg-transparent text-text-color-400 !py-2 !px-3 !mr-2 hover:text-primary transition" >Login</Button>
                            {/* <Button className="!px-8 !py-2 !rounded-sm font-semibold bg-primary-dark hover:scale-105 transition" textWhite shadow>Create Event</Button> */}
                        </div>
                    </>
                ) : (
                    <div className="sidebar-toggle">
                        {sidebar ? (
                            <Icons.VscChromeClose
                                className="sidebar-toggle-logo z-50 transition !text-primary-dark"
                                onClick={() => setSidebar(!sidebar)}
                            />
                        ) : (
                            <Iconh.HiOutlineBars3BottomRight
                                className="sidebar-toggle-logo transition !text-primary-dark"
                                onClick={() => setSidebar(!sidebar)}
                            />
                        )}
                    </div>
                )}
            </div>

            <div className={sidebar ? "sidebar active px-2 py-4 shadow-right" : "sidebar"} >
                <div className='span__pseudo' onClick={() => setSidebar(!sidebar)}></div>
                <Link 
                to="/" 
                onClick={() => 
                {
                    setSidebar(!sidebar)
                }} >
                    <TickitIcon className='mx-auto hover:scale-105 transition-[.3] cursor-pointer' />
                </Link>

                <ul className="sidebar-items w-full">
                    {header2NavItems.map((item) => {
                        if (item.title === 'Contact Us') {
                            return (
                                <li key={item.id} 
                                className={`${item.sName} w-full`}
                                >
                                    <a
                                        href="mailto:hello@tickit.io?subject=Making Inquiries at Tickit&body=Hi Tickit"
                                        target="_blank" rel="noopener noreferrer"
                                        className={`!text-lg text-start w-full ${pathname === item.path ? 'text-primary' 
                                        : 'text-text-color-400'} hover:border-b hover:border-primary hover:text-white !px-2`}>
                                        {item.title}
                                    </a>
                                </li>
                            );
                        }
                        return (
                            <li key={item.id} className={`${item.sName} w-full`}>
                                <Link to={item.pathroute}
                                    className={`!text-lg text-start w-full ${pathname === item.path ? 'text-primary' : 'text-text-color-400'} hover:border-b hover:border-primary hover:text-white !px-2`}
                                    onClick={() => 
                                    {
                                        // console.log(item.pathroute);
                                        setSidebar(!sidebar)
                                    }}>{item.title}</Link>
                            </li>
                        );
                    })
                    }
                </ul>
                <div className='flex flex-col mt-24'>
                    <Button to="/login" 
                    onClick={() => 
                    {
                        setSidebar(!sidebar)
                    }} 
                    className="mb-0 font-medium !px-2 hover:text-primary transition" >Login</Button>
                    <Button to="/signup" 
                    onClick={() => 
                        {
                            setSidebar(!sidebar)
                        }} 
                    className="mb-2 font-medium !px-2 hover:text-primary transition" >Sign Up</Button>
                    {/* <Button className="font-medium hover:bg-primary-dark transition hover:scale-105" textWhite bgPrimary shadow>Create Event</Button> */}
                </div>
            </div>
        </>
    );
};

export default Header2;