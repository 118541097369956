import React, { useState, useEffect, useContext } from 'react';
// import Button from '../../../UI_Elements/Button/Button';
import * as Icons from "react-icons/vsc";
import * as Iconh from "react-icons/hi2";
import "./Navbar.css";
// import { navItems } from '../../../constants';
import { TickitIcon } from '../SvgIcons';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { createPopper } from "@popperjs/core";
// import { AiOutlineLogout } from 'react-icons/ai';
// import { FaUserCircle } from 'react-icons/fa';
import { MdArrowDropDown } from 'react-icons/md';
import images from '../../../assets';
import {AuthContext} from '../../../context/auth-context';
import { DataContext } from '../../../context/DataContext';


const HeaderDashboard = () => {
    const [mobile, setMobile] = useState(false);
    const [sidebar, setSidebar] = useState(false);
    const [navbar, setNavbar] = useState(false);

    const navigate = useNavigate()

    const auth = useContext(AuthContext)
    const data = useContext(DataContext)

    
     // dropdown props
     const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
     const btnDropdownRef = React.createRef();
     const popperDropdownRef = React.createRef();
     const openDropdownPopover = () => {
     createPopper(btnDropdownRef.current, popperDropdownRef.current, {
         placement: "bottom-end",
     });
     setDropdownPopoverShow(true);
 
     };
     const closeDropdownPopover = () => {
     setDropdownPopoverShow(false);
 
     };
 
     useEffect(() => {
 
         // Hide on outside click
       const hideOnClickOutside = (e) => {
         if( popperDropdownRef.current && !popperDropdownRef.current.contains(e.target) ) {
           setDropdownPopoverShow(false);
         }
       }
         
         document.addEventListener("click", hideOnClickOutside, true)
       }, [dropdownPopoverShow, popperDropdownRef] )

    //Logout Handler
    const logoutHandler = () =>{
        auth.logout()
        localStorage.removeItem("profileData")
        navigate('/')
      }

    //dashboard Handler
    const dashboardHandler = () =>{
    navigate('/dashboard/')
    }

    //dashboard Handler
    const settingsHandler = () =>{
        navigate('/settings/')
      }

    const location = useLocation();
    const pathname = location.pathname;

    // Extract the dynamic part from the pathname
    const dynamicPart = pathname.substring(pathname.lastIndexOf('/') + 1);
   

    // Check if the dynamic part is a number
    const isNumber = !isNaN(dynamicPart) && parseInt(dynamicPart) >= 1;

    const header2NavItems = [
        {
            id: 1,
            title: "Home",
            path: "/",
            pathroute: "/",
            nName: "nav-item",
            sName: "sidebar-item"
        },
        // {
        //     id: 2,
        //     title: "About Us",
        //     path: "/about",
        //     pathroute: "/about",
        //     nName: "nav-item",
        //     sName: "sidebar-item"
        // },
        {
            id: 3,
            title: "Tickets",
            path: "/browse" + (isNumber ? `/${dynamicPart}` : ""),
            pathroute: "/browse",
            nName: "nav-item",
            sName: "sidebar-item"
        },
        // {
        //     id: 4,
        //     title: "Contact Us",
        //     path: "/contactus",
        //     pathroute: "/contactus",
        //     nName: "nav-item",
        //     sName: "sidebar-item"
        // }
    ];

    const setFixedHandler = () => {
        window.scrollY >= 390 ? setNavbar(true) : setNavbar(false);
    };

    window.addEventListener("scroll", setFixedHandler);

    useEffect(() => {
        if (window.innerWidth < 960) {
            setMobile(true);
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 960) {
                setMobile(true);
            } else {
                setMobile(false);
                setSidebar(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <div className={`flex ${navbar ? "fixed top-0 w-full backdrop-blur-md bg-white/30 z-40 transition-[0.5]" 
            : "fixed z-40 w-full top-0"} justify-between bg-white drop-shadow-lg items-center px-6 md:px-12 lg:px-12 xl:px-24 py-4 
            lg:py-6 lg:pt-4`}>
                <Link to="/" className="hover:scale-105 transition-[.3] cursor-pointer">
                    <TickitIcon />
                </Link>

                {!mobile ? (
                    <>
                        <ul className='flex gap-8 '>
                            {header2NavItems.map((item) => {
                                if (item.title === 'Contact Us') {
                                    return (
                                        <li key={item.id} className={`${item.nName} border-b border-transparent hover:border-primary 
                                        ${pathname === item.path ? 'text-primary border-primary' : 'text-text-color-400'} transition`}>
                                            <a
                                                href="mailto:hello@tickit.io?subject=Making Inquiries at Tickit&body=Hi Tickit"
                                                target="_blank" rel="noopener noreferrer"
                                                className={`${item.nName} text-lg `}>
                                                {item.title}
                                            </a>
                                        </li>
                                    );
                                }
                                return (
                                    <li key={item.id} className={`${item.nName} border-b !font-normal border-transparent hover:border-primary 
                                    ${pathname === item.path ? 'text-primary border-primary' : 'text-text-color-400'} transition`}>
                                        <Link to={item.pathroute} className={`${item.nName} text-lg`}>{item.title}</Link>
                                    </li>
                                );
                            })}
                        </ul>

                        <div className='flex justify-between text-lg'>
                            <div className='flex justify-center items-center cursor-pointer' 
                            ref={btnDropdownRef}
                            onClick={(e) => {
                            e.preventDefault();
                            dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
                            }}>
                                {/* <FaUserCircle className='mr-3 text-black-text'/> */}
                                <img src={data?.userData?.profile_picture || images.logo['src']} alt={images.logo['alt']} className='w-8 h-8' 
                                onError={ev => ev.target.src = images.logo['src']}/>
                                <MdArrowDropDown className='text-black-text'/>
                            </div>
                            <div
                                ref={popperDropdownRef}
                                className={
                                (dropdownPopoverShow ? "block " : "hidden ") +
                                " bg-white text-base z-50 py-2 list-none text-left rounded shadow-lg min-w-40"
                                }
                            >
                                <button
                                className={
                                    "text-sm py-2 px-4 flex justify-between items-center w-full whitespace-nowrap text-black-text font-semibold hover:bg-primary hover:text-white"
                                }
                                onClick={()=>{
                                    dashboardHandler()
                                    closeDropdownPopover()
                                }}
                                >
                                Dashboard
                                </button>

                                <button
                                className={
                                    "text-sm py-2 px-4 flex justify-between items-center w-full whitespace-nowrap text-black-text font-semibold hover:bg-primary hover:text-white"
                                }
                                onClick={() =>{
                                    settingsHandler()
                                    closeDropdownPopover()
                                }}
                                >
                                Settings
                                </button>

                                <button
                                className={
                                    "text-sm py-2 px-4 flex justify-between items-center w-full whitespace-nowrap text-black-text font-semibold hover:bg-primary hover:text-white"
                                }
                                onClick={() =>{
                                    logoutHandler()
                                    closeDropdownPopover()
                                }}
                                >
                                Log Out
                                {/* <AiOutlineLogout className='text-black-text hover:text-primary hover:text-opacity-100 '/> */}
                                </button>
                                
                            </div>
                        </div>
                    </>
                ) : (
                    
                    <div className="sidebar-toggle">

                    <div className='flex justify-between text-lg'>
                            <div className='flex justify-center items-center cursor-pointer' 
                            ref={btnDropdownRef}
                            onClick={(e) => {
                            e.preventDefault();
                            dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
                            }}>
                                {/* <FaUserCircle className='mr-3 text-black-text'/> */}
                                <img src={data?.userData?.profile_picture || images.profileImg['src']} alt={images.profileImg['alt']} 
                                className='w-8 h-8' 
                                onError={ev => ev.target.src = images.profileImg['src']}/>
                                <MdArrowDropDown className='text-black-text'/>
                            </div>
                            <div
                                ref={popperDropdownRef}
                                className={
                                (dropdownPopoverShow ? "block " : "hidden ") +
                                " bg-white text-base z-50 py-2 list-none text-left rounded shadow-lg min-w-40"
                                }
                            >
                                <button
                                className={
                                    "text-sm py-2 px-4 flex justify-between items-center w-full whitespace-nowrap text-black-text font-semibold hover:bg-primary hover:text-white"
                                }
                                onClick={()=>{
                                    dashboardHandler()
                                    closeDropdownPopover()
                                }}
                                >
                                Dashboard
                                </button>

                                <button
                                className={
                                    "text-sm py-2 px-4 flex justify-between items-center w-full whitespace-nowrap text-black-text font-semibold hover:bg-primary hover:text-white"
                                }
                                onClick={() =>{
                                    settingsHandler()
                                    closeDropdownPopover()
                                }}
                                >
                                Settings
                                </button>

                                <button
                                className={
                                    "text-sm py-2 px-4 flex justify-between items-center w-full whitespace-nowrap text-black-text font-semibold hover:bg-primary hover:text-white"
                                }
                                onClick={() =>{
                                    logoutHandler()
                                    closeDropdownPopover()
                                }}
                                >
                                Log Out
                                {/* <AiOutlineLogout className='text-black-text hover:text-primary hover:text-opacity-100 '/> */}
                                </button>
                                
                            </div>
                        </div>

                        {sidebar ? (
                            <Icons.VscChromeClose
                                className="sidebar-toggle-logo z-50 transition !text-primary-dark"
                                onClick={() => setSidebar(!sidebar)}
                            />
                        ) : (
                            <Iconh.HiOutlineBars3BottomRight
                                className="sidebar-toggle-logo transition !text-primary-dark"
                                onClick={() => setSidebar(!sidebar)}
                            />
                        )}
                    </div>
                )}
            </div>

            <div className={sidebar ? "sidebar active px-2 py-4 shadow-right" : "sidebar"} >
                <div className='span__pseudo' onClick={() => setSidebar(!sidebar)}></div>
                <Link to="/">
                    <TickitIcon className='mx-auto hover:scale-105 transition-[.3] cursor-pointer' />
                </Link>

                <ul className="sidebar-items w-full">
                    {header2NavItems.map((item) => {
                        // if (item.title === 'Contact Us') {
                        //     return (
                        //         <li key={item.id} 
                        //         className={`${item.sName} w-full`}
                        //         >
                        //             <a
                        //                 href="mailto:hello@tickit.io?subject=Making Inquiries at Tickit&body=Hi Tickit"
                        //                 target="_blank" rel="noopener noreferrer"
                        //                 className={`!text-lg text-start w-full ${pathname === item.path ? 'text-primary' 
                        //                 : 'text-text-color-400'} hover:border-b hover:border-primary hover:text-white !px-2`}>
                        //                 {item.title}
                        //             </a>
                        //         </li>
                        //     );
                        // }
                        return (
                            <li key={item.id} className={`${item.sName} w-full`}>
                                <Link to={item.path}
                                    className={`!text-lg text-start w-full ${pathname === item.path ? 'text-primary' : 'text-text-color-400'} hover:border-b hover:border-primary hover:text-white !px-2`}
                                    onClick={() => setSidebar(!sidebar)}>{item.title}</Link>
                            </li>
                        );
                    })
                    }
                </ul>
                {/* <div className='flex flex-col mt-24'>
                    <Button to="/login" className="mb-0 font-medium !px-2 hover:text-primary transition" >Login</Button>
                    <Button to="/signup" className="mb-2 font-medium !px-2 hover:text-primary transition" >Sign Up</Button>
                    <Button className="font-medium hover:bg-primary-dark transition hover:scale-105" textWhite bgPrimary shadow>Create Event</Button>
                </div> */}
            </div>
        </>
    );
};

export default HeaderDashboard;