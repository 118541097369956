import React from 'react';
import { BookingDoneSvgIcon } from '../SvgIcons';

const SuccessModal = ({ isOpen, onClose, title, text }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-30"
            onClick={onClose}
        >
            <div className="bg-gray-400 bg-opacity-75 absolute inset-0" />
            <div className="bg-white rounded-lg drop-shadow-lg py-8 sm:py-16 xl:w-[30%] mt-20 mx-4 xs:mx-8 md:mx-0 overflow-y-auto max-h-[calc(100vh-8rem)] flex flex-col justify-center items-center">
                <BookingDoneSvgIcon />
                <div className="flex flex-col items-center mt-5 gap-2 pb-3 px-2 sm:px-10">
                    <h2 className="text-2xl text-success-400 font-bold text-center">{title || ""}</h2>
                    <p className='text-sm sm:text-lg text-center text-text-color-400 leading-[1.8] border-b-2 pb-4'>{text || ""}</p>
                </div>

            </div>
        </div>
    );
};

export default SuccessModal;

