import React, {useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useHttpClient } from '../../../hooks/http-hook';
import { useForm } from '../../../hooks/form-hook';
import { VALIDATOR_REQUIRE } from '../../../util/validators';
import Input from '../../../UI_Elements/Input/Input';
import { useSearchParams } from 'react-router-dom';
// import { useEvent } from '../../../api/events-hook';
import Loader from '../../../UI_Elements/loader/Loader';
import moment from 'moment';
import images from '../../../assets';
import { useTicket } from '../../../api/ticket-hook';
import SuccessModal from '../../Reusables/Modals/SuccessModal';
import FailureModal from '../../Reusables/Modals/FailureModal';



const Index = () => {
    const [eventDetails, seteventDetails] = useState()
    const [ticketDetails, setticketDetails] = useState()
    const {statusLoading, getTicket} = useTicket()
    const [openSuccessModal, setOpenSuccessModal] = useState(false)
    const [openFailureModal, setOpenFailureModal] = useState(false)

    const [searchParams] = useSearchParams();
    const { eventId, ticket_id } = Object.fromEntries(searchParams);

    const {isLoading, error, sendRequest} = useHttpClient();

    const [formState,
        inputHandler] = useForm({
        organiser_passcode: {
            value: '',
            isValid: false
        }
    }, false);

    useEffect(() =>{
        getTicket("TICKETS-DETAILS", `?ticket_id=${ticket_id}`).then((res) => {
            if(res.ticket.event){
                seteventDetails(res.ticket.event)
            }
            else{
                seteventDetails(res.ticket.location)
            }
           
            setticketDetails(res.ticket)
        }).catch((error) => {
            console.log(error)
        })
    },[eventId, getTicket, ticket_id])


    const authSubmitHandler = async event => {
        event.preventDefault();
        

        try {
            const organiser_passcode = formState.inputs.organiser_passcode.value;
            const formData = new FormData()
            formData.append('organiser_passcode', formState.inputs.organiser_passcode.value)
            
            const responseData = await
            sendRequest(`${process.env.REACT_APP_API_CALL}/ticket/validate?ticket_id=${ticket_id}&code=${organiser_passcode}`, 'POST', formData, {});
            
            if(responseData){
                successModalHandler()
            }

        } catch (err) {
            failureModalHandler()
            console.log(error)
        }
        
    };

    const successModalHandler = () => {
        setOpenSuccessModal(!openSuccessModal)
    }

    const failureModalHandler = () => {
        setOpenFailureModal(!openFailureModal)
    }

    return (
        <> 
            <SuccessModal 
            title="Ticket successfully validated!" 
            text="This ticket is currently valid and scheduled for specified date" 
            isOpen={openSuccessModal} 
            onClose={successModalHandler}/>
            <FailureModal
            title="Ticket validation failed!" 
            text="This ticket is currently invalid. Please kindly check the date of the ticket or Check if you have the right ticket." 
            isOpen={openFailureModal} 
            onClose={failureModalHandler}/>
           <div className='min-h-screen overflow-y-scroll w-full flex flex-col justify-center items-center pt-28 pb-16'>
                {statusLoading && <Loader/> }

                {eventDetails && <div className='bg-white shadow-md p-4 min-w-48 mx-auto'>
                <div className="flex flex-col items-center gap-2 pb-3 px-2 sm:px-20">
                    <h2 className="text-xl mb-2 text-text-color-400 font-bold text-center">Ticket Validation</h2>
                    <img src={`${eventDetails.image}`} alt="event cover"  className='object-cover w-48 h-48 mx-auto mt-6 mb-4' 
                    onError={(ev) =>{
                        ev.target.src=images.ticketFlyer.src
                    }}/>
                    <p className='text-sm sm:text-base text-center font-semibold text-text-color-400 leading-[1.8]'>{eventDetails.name || ""}</p>
                    <div className="flex flex-col items-center text-text-color-200 text-base gap-2">
                        <span className='text-sm sm:text-base text-center'>{`${eventDetails?.address || ""} ${eventDetails.state || ""}`}</span>
                        {!ticketDetails.location ? <span className='text-sm sm:text-base text-center'>Ticket Date: {moment(eventDetails.start_time).format('ddd, MMM DD · HH:mm:ss A') || "not set"}</span> 
                        : <span className='text-sm sm:text-base text-center'>Ticket Date: {moment(eventDetails.date).format('ddd, MMM DD · HH:mm:ss A') || "not set"}</span>}
                    </div>
                </div>

                <form className='my-6 px-4' onSubmit={authSubmitHandler}>
                    <Input
                        type="text"
                        placeholder='Enter your organiser passcode'
                        label="Organiser Passcode*"
                        labelClassName={`text-sm mb-2 font-semibold text-black-text form_login_label`}
                        id="organiser_passcode"
                        validators={[VALIDATOR_REQUIRE()]}
                        errorText="Please enter a valid organiser passcode"
                        onInput={inputHandler}
                        divClassName={`mb-3 `}
                        containerClassName={`relative  `}
                        className={`appearance-none ${formState.inputs.organiser_passcode.value && "bg-light-purple"}  
                        input-form p-3 text-black-text focus:outline-none 
                        focus:ring-none focus:border-none focus:z-10 sm:text-sm`}/>

                    

                    <button type="submit" className={`flex items-center justify-center w-full 
                    cursor-pointer bg-primary-dark hover:bg-primary text-white py-3 rounded-xl mt-4`}>
                        Validate Ticket {isLoading && <span className='text-white animate-spin ml-2'><AiOutlineLoading3Quarters/></span>}
                    </button>
                
                </form>
            </div>
            }
           </div>

        </>
    );
};

export default Index;